// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomInputMask_wrapper__MZgZc {
  display: flex;
  flex-direction: column;
}
.CustomInputMask_wrapper__MZgZc .CustomInputMask_label__jbVTN {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: rgb(67, 69, 79);
}
.CustomInputMask_wrapper__MZgZc .CustomInputMask_input__k4RlZ {
  padding-left: 15px;
  margin-top: 5px;
  border: 1px solid #dadcdc;
  border-radius: 10px;
  height: 40px;
}
.CustomInputMask_wrapper__MZgZc .CustomInputMask_input__k4RlZ:focus {
  outline: none;
  border: 2px solid black;
}`, "",{"version":3,"sources":["webpack://./src/components/common/CustomInputMask/CustomInputMask.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF;AAGE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AADJ;AAIE;EACE,kBAAA;EACA,eAAA;EACA,yBAAA;EACA,mBAAA;EACA,YAAA;AAFJ;AAII;EACE,aAAA;EACA,uBAAA;AAFN","sourcesContent":["@import \"../../../styles/_variables.scss\";\n\n.wrapper {\n  display: flex;\n  flex-direction: column;\n\n  .label {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 26px;\n    color: rgba(67, 69, 79, 1);\n  }\n\n  .input {\n    padding-left: 15px;\n    margin-top: 5px;\n    border: 1px solid #dadcdc;\n    border-radius: 10px;\n    height: 40px;\n\n    &:focus {\n      outline: none;\n      border: 2px solid black;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CustomInputMask_wrapper__MZgZc`,
	"label": `CustomInputMask_label__jbVTN`,
	"input": `CustomInputMask_input__k4RlZ`
};
export default ___CSS_LOADER_EXPORT___;
