// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransactionDeleteModal_modal__7liPC {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
}
.TransactionDeleteModal_modal__7liPC .TransactionDeleteModal_wrapper__o\\+I1U {
  border-radius: 12px;
  background-color: #f7f8f9;
  width: 40vw;
}
.TransactionDeleteModal_modal__7liPC .TransactionDeleteModal_wrapper__o\\+I1U .TransactionDeleteModal_content__36OFV {
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}
.TransactionDeleteModal_modal__7liPC .TransactionDeleteModal_wrapper__o\\+I1U .TransactionDeleteModal_content__36OFV h1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 113.7%;
}
.TransactionDeleteModal_modal__7liPC .TransactionDeleteModal_wrapper__o\\+I1U .TransactionDeleteModal_content__36OFV p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
}
.TransactionDeleteModal_modal__7liPC .TransactionDeleteModal_wrapper__o\\+I1U .TransactionDeleteModal_content__36OFV .TransactionDeleteModal_btns__qJ\\+EM {
  display: flex;
  column-gap: 15px;
  margin-top: 30px;
}
@media (max-width: 1130px) {
  .TransactionDeleteModal_modal__7liPC .TransactionDeleteModal_wrapper__o\\+I1U {
    width: 50vw;
  }
}
@media (max-width: 540px) {
  .TransactionDeleteModal_modal__7liPC .TransactionDeleteModal_wrapper__o\\+I1U {
    width: 80vw;
  }
}

.TransactionDeleteModal_active__tQd8q {
  opacity: 1;
  pointer-events: all;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/TransactionResultPage/TransactionDeleteModal/TransactionDeleteModal.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,oCAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;EACA,oBAAA;EACA,gBAAA;AADF;AAGE;EACE,mBAAA;EACA,yBAAA;EACA,WAAA;AADJ;AAGI;EACE,aAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;AADN;AAGM;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;AADR;AAIM;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAFR;AAKM;EACE,aAAA;EACA,gBAAA;EACA,gBAAA;AAHR;AAOI;EACE;IACE,WAAA;EALN;AACF;AAQI;EACE;IACE,WAAA;EANN;AACF;;AAWA;EACE,UAAA;EACA,mBAAA;AARF","sourcesContent":["@import \"../../../../styles/_variables.scss\";\n\n.modal {\n  height: 100vh;\n  width: 100vw;\n  background-color: rgba(0, 0, 0, 0.4);\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 999;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  opacity: 0;\n  pointer-events: none;\n  transition: 0.5s;\n\n  .wrapper {\n    border-radius: 12px;\n    background-color: #f7f8f9;\n    width: 40vw;\n\n    .content {\n      padding: 50px;\n      display: flex;\n      flex-direction: column;\n      align-items: center;\n      row-gap: 10px;\n\n      h1 {\n        font-weight: 500;\n        font-size: 40px;\n        line-height: 113.7%;\n      }\n\n      p {\n        font-weight: 400;\n        font-size: 16px;\n        line-height: 26px;\n      }\n\n      .btns {\n        display: flex;\n        column-gap: 15px;\n        margin-top: 30px;\n      }\n    }\n\n    @media (max-width: 1130px) {\n      & {\n        width: 50vw;\n      }\n    }\n\n    @media (max-width: 540px) {\n      & {\n        width: 80vw;\n      }\n    }\n  }\n}\n\n.active {\n  opacity: 1;\n  pointer-events: all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `TransactionDeleteModal_modal__7liPC`,
	"wrapper": `TransactionDeleteModal_wrapper__o+I1U`,
	"content": `TransactionDeleteModal_content__36OFV`,
	"btns": `TransactionDeleteModal_btns__qJ+EM`,
	"active": `TransactionDeleteModal_active__tQd8q`
};
export default ___CSS_LOADER_EXPORT___;
