import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { getTokenCookie, clearCookies } from "../utils/jwt";
import { camelizeKeys, decamelizeKeys } from "humps";

const setAuthorizationHeader = (config: InternalAxiosRequestConfig) => {
  const token = getTokenCookie();

  if (token) {
    config.headers!.Authorization = `Bearer ${token}`;
  }

  return config;
};

const transformDataToSnakeCase = (config: InternalAxiosRequestConfig) => {
  if (config.data) {
    config.data = decamelizeKeys(config.data);
  }

  return config;
};

const transformDataToCamelCase = (response: AxiosResponse) => {
  if (response.data) {
    response.data = camelizeKeys(response.data);
  }

  return response;
};

const redirectToSignIn = () => {
  clearCookies();
  window.location.href = "/auth/sign-in";
};

const $api = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
});

$api.interceptors.request.use(setAuthorizationHeader);

$api.interceptors.request.use(transformDataToSnakeCase);

$api.interceptors.response.use(transformDataToCamelCase);

$api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      if (error.response.data.code === "token_not_valid") {
        redirectToSignIn();
      }
    }
    return Promise.reject(error);
  }
);

export default $api;
