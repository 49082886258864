// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavigationPanel_panel__gsrdn .NavigationPanel_navigation__3VfN7 {
  display: grid;
  row-gap: 10px;
  margin-top: 50px;
}
.NavigationPanel_panel__gsrdn .NavigationPanel_navigation__3VfN7 .NavigationPanel_item__pC9K8 {
  display: flex;
  align-items: center;
  column-gap: 18px;
  padding: 10px 20px;
  cursor: pointer;
}
.NavigationPanel_panel__gsrdn .NavigationPanel_navigation__3VfN7 .NavigationPanel_item__pC9K8 p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}
.NavigationPanel_panel__gsrdn .NavigationPanel_navigation__3VfN7 .NavigationPanel_item__pC9K8 svg {
  width: 20px;
  height: 20px;
}
.NavigationPanel_panel__gsrdn .NavigationPanel_navigation__3VfN7 .NavigationPanel_item__pC9K8:hover {
  background: linear-gradient(0deg, #54e9b8, #54e9b8), #ffffff;
  border-radius: 10px;
}
.NavigationPanel_panel__gsrdn .NavigationPanel_navigation__3VfN7 .NavigationPanel_item__pC9K8:hover p {
  font-weight: 600;
}

.NavigationPanel_active__str3a {
  background: linear-gradient(0deg, #54e9b8, #54e9b8), #ffffff;
  border-radius: 10px;
}
.NavigationPanel_active__str3a p {
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/DashboardLayout/NavigationPanel/NavigationPanel.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,aAAA;EACA,gBAAA;AAAJ;AAEI;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;AAAN;AAEM;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAAR;AAGM;EACE,WAAA;EACA,YAAA;AADR;AAIM;EACE,4DAAA;EACA,mBAAA;AAFR;AAIQ;EACE,gBAAA;AAFV;;AASA;EACE,4DAAA;EACA,mBAAA;AANF;AAQE;EACE,gBAAA;AANJ","sourcesContent":[".panel {\n  .navigation {\n    display: grid;\n    row-gap: 10px;\n    margin-top: 50px;\n\n    .item {\n      display: flex;\n      align-items: center;\n      column-gap: 18px;\n      padding: 10px 20px;\n      cursor: pointer;\n\n      p {\n        font-weight: 400;\n        font-size: 16px;\n        line-height: 22px;\n      }\n\n      svg {\n        width: 20px;\n        height: 20px;\n      }\n\n      &:hover {\n        background: linear-gradient(0deg, #54e9b8, #54e9b8), #ffffff;\n        border-radius: 10px;\n\n        p {\n          font-weight: 600;\n        }\n      }\n    }\n  }\n}\n\n.active {\n  background: linear-gradient(0deg, #54e9b8, #54e9b8), #ffffff;\n  border-radius: 10px;\n\n  p {\n    font-weight: 600;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": `NavigationPanel_panel__gsrdn`,
	"navigation": `NavigationPanel_navigation__3VfN7`,
	"item": `NavigationPanel_item__pC9K8`,
	"active": `NavigationPanel_active__str3a`
};
export default ___CSS_LOADER_EXPORT___;
