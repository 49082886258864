const getStatusLabel = (status: string): string => {
  switch (status) {
    case "PENDING":
      return "Запрос отправлен";
    case "ACCEPTED":
      return "Запрос принят";
    case "COMPARE_DB":
      return "Сравнение завершено";
    case "SHARE_DB":
      return "База обогащена";
    case "CONTINUE":
      return "Сравнение без обмена"
    case "CONFIRMATION":
      return "Подтверждение"
    default:
      return "";
  }
};

export default getStatusLabel;
