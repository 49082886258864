// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TinkoffPaymentWidget_modal__jegRh {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;
}
.TinkoffPaymentWidget_modal__jegRh .TinkoffPaymentWidget_wrapper__2jSG1 {
  border-radius: 12px;
  background-color: #f7f8f9;
  width: 30vw;
}
.TinkoffPaymentWidget_modal__jegRh .TinkoffPaymentWidget_wrapper__2jSG1 .TinkoffPaymentWidget_title__1JOvK {
  padding: 25px;
  background-color: #fff;
  border-radius: 12px 12px 0 0;
}
.TinkoffPaymentWidget_modal__jegRh .TinkoffPaymentWidget_wrapper__2jSG1 .TinkoffPaymentWidget_title__1JOvK h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  margin-bottom: 0;
}
.TinkoffPaymentWidget_modal__jegRh .TinkoffPaymentWidget_wrapper__2jSG1 .TinkoffPaymentWidget_content__ydWAH {
  padding: 25px;
}
.TinkoffPaymentWidget_modal__jegRh .TinkoffPaymentWidget_wrapper__2jSG1 .TinkoffPaymentWidget_content__ydWAH .TinkoffPaymentWidget_form__wJF6Q {
  display: grid;
  row-gap: 20px;
}
@media (max-width: 1130px) {
  .TinkoffPaymentWidget_modal__jegRh .TinkoffPaymentWidget_wrapper__2jSG1 {
    width: 50vw;
  }
}
@media (max-width: 540px) {
  .TinkoffPaymentWidget_modal__jegRh .TinkoffPaymentWidget_wrapper__2jSG1 {
    width: 80vw;
  }
}

.TinkoffPaymentWidget_active__mOUom {
  opacity: 1;
  pointer-events: all;
}`, "",{"version":3,"sources":["webpack://./src/components/common/TinkoffPaymentWidget/TinkoffPaymentWidget.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,YAAA;EACA,oCAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;EACA,oBAAA;EACA,gBAAA;AADF;AAGE;EACE,mBAAA;EACA,yBAAA;EACA,WAAA;AADJ;AAGI;EACE,aAAA;EACA,sBAAA;EACA,4BAAA;AADN;AAGM;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;AADR;AAKI;EACE,aAAA;AAHN;AAKM;EACE,aAAA;EAEA,aAAA;AAJR;AAQI;EACE;IACE,WAAA;EANN;AACF;AASI;EACE;IACE,WAAA;EAPN;AACF;;AAYA;EACE,UAAA;EACA,mBAAA;AATF","sourcesContent":["@import \"../../../styles/_variables.scss\";\n\n.modal {\n  height: 100vh;\n  width: 100vw;\n  background-color: rgba(0, 0, 0, 0.4);\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 999;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  opacity: 0;\n  pointer-events: none;\n  transition: 0.5s;\n\n  .wrapper {\n    border-radius: 12px;\n    background-color: #f7f8f9;\n    width: 30vw;\n\n    .title {\n      padding: 25px;\n      background-color: #fff;\n      border-radius: 12px 12px 0 0;\n\n      h2 {\n        font-weight: 700;\n        font-size: 20px;\n        line-height: 23px;\n        margin-bottom: 0;\n      }\n    }\n\n    .content {\n      padding: 25px;\n\n      .form {\n        display: grid;\n        // grid-template-columns: 1fr;\n        row-gap: 20px;\n      }\n    }\n\n    @media (max-width: 1130px) {\n      & {\n        width: 50vw;\n      }\n    }\n\n    @media (max-width: 540px) {\n      & {\n        width: 80vw;\n      }\n    }\n  }\n}\n\n.active {\n  opacity: 1;\n  pointer-events: all;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `TinkoffPaymentWidget_modal__jegRh`,
	"wrapper": `TinkoffPaymentWidget_wrapper__2jSG1`,
	"title": `TinkoffPaymentWidget_title__1JOvK`,
	"content": `TinkoffPaymentWidget_content__ydWAH`,
	"form": `TinkoffPaymentWidget_form__wJF6Q`,
	"active": `TinkoffPaymentWidget_active__mOUom`
};
export default ___CSS_LOADER_EXPORT___;
