// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoadingAnimationWave_waveWrapper__ekJuI {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.LoadingAnimationWave_waveWrapper__ekJuI div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #000000;
  animation: LoadingAnimationWave_waveWrapper__ekJuI 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.LoadingAnimationWave_waveWrapper__ekJuI div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.LoadingAnimationWave_waveWrapper__ekJuI div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.LoadingAnimationWave_waveWrapper__ekJuI div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}

@keyframes LoadingAnimationWave_waveWrapper__ekJuI {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/LoadingPopup/LoadingAnimationWave.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACF;AACE;EACE,qBAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,mBAAA;EACA,6FAAA;AACJ;AAEE;EACE,SAAA;EACA,uBAAA;AAAJ;AAGE;EACE,UAAA;EACA,uBAAA;AADJ;AAIE;EACE,UAAA;EACA,kBAAA;AAFJ;;AAMA;EACE;IACE,QAAA;IACA,YAAA;EAHF;EAKA;IACE,SAAA;IACA,YAAA;EAHF;AACF","sourcesContent":[".waveWrapper {\n  display: inline-block;\n  position: relative;\n  width: 80px;\n  height: 80px;\n\n  div {\n    display: inline-block;\n    position: absolute;\n    left: 8px;\n    width: 16px;\n    background: #000000;\n    animation: waveWrapper 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;\n  }\n\n  div:nth-child(1) {\n    left: 8px;\n    animation-delay: -0.24s;\n  }\n\n  div:nth-child(2) {\n    left: 32px;\n    animation-delay: -0.12s;\n  }\n\n  div:nth-child(3) {\n    left: 56px;\n    animation-delay: 0;\n  }\n}\n\n@keyframes waveWrapper {\n  0% {\n    top: 8px;\n    height: 64px;\n  }\n  50%, 100% {\n    top: 24px;\n    height: 32px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"waveWrapper": `LoadingAnimationWave_waveWrapper__ekJuI`
};
export default ___CSS_LOADER_EXPORT___;
