import React from "react";
import { useUser } from "../../hooks/useUser";
import { Outlet, Navigate } from "react-router-dom";
import { Loading } from "../index.components";

export const PrivateRoute = (): JSX.Element => {
  const { isAuthenticated } = useUser();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    setIsLoading(true);

    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to={"/auth/sign-in"} />;
};
