// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfilePage_profilePage__Em2eZ h1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 113.7%;
}
.ProfilePage_profilePage__Em2eZ .ProfilePage_profile__hesWn {
  margin-top: 50px;
  display: flex;
  column-gap: 40px;
}
.ProfilePage_profilePage__Em2eZ .ProfilePage_profile__hesWn .ProfilePage_image__Dsnzk {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: black;
  overflow: hidden;
}
.ProfilePage_profilePage__Em2eZ .ProfilePage_profile__hesWn .ProfilePage_image__Dsnzk .ProfilePage_avatar__axjph {
  width: 100%;
  height: 100%;
}
.ProfilePage_profilePage__Em2eZ .ProfilePage_profile__hesWn .ProfilePage_image__Dsnzk .ProfilePage_defaultAvatar__4nckz {
  width: 120px;
  height: 120px;
}
.ProfilePage_profilePage__Em2eZ .ProfilePage_profile__hesWn .ProfilePage_info__5Yg37 {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.ProfilePage_profilePage__Em2eZ .ProfilePage_profile__hesWn .ProfilePage_info__5Yg37 .ProfilePage_firstName__Cy1Zj {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}
.ProfilePage_profilePage__Em2eZ .ProfilePage_profile__hesWn .ProfilePage_info__5Yg37 .ProfilePage_phoneNumber__PqFut,
.ProfilePage_profilePage__Em2eZ .ProfilePage_profile__hesWn .ProfilePage_info__5Yg37 .ProfilePage_email__VcWJz {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: rgb(67, 69, 79);
}
.ProfilePage_profilePage__Em2eZ .ProfilePage_statistics__Y--mQ {
  margin-top: 80px;
}
.ProfilePage_profilePage__Em2eZ .ProfilePage_statistics__Y--mQ h2 {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 30px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/ProfilePage/ProfilePage.module.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,aAAA;EACA,gBAAA;AADJ;AAGI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,uBAAA;EACA,gBAAA;AADN;AAGM;EACE,WAAA;EACA,YAAA;AADR;AAIM;EACE,YAAA;EACA,aAAA;AAFR;AAMI;EACE,aAAA;EACA,sBAAA;EACA,aAAA;AAJN;AAMM;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAJR;AAOM;;EAEE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AALR;AAUE;EACE,gBAAA;AARJ;AAUI;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,mBAAA;AARN","sourcesContent":[".profilePage {\n  h1 {\n    font-weight: 500;\n    font-size: 40px;\n    line-height: 113.7%;\n  }\n\n  .profile {\n    margin-top: 50px;\n    display: flex;\n    column-gap: 40px;\n\n    .image {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      width: 160px;\n      height: 160px;\n      border-radius: 50%;\n      background-color: black;\n      overflow: hidden;\n\n      .avatar {\n        width: 100%;\n        height: 100%;\n      }\n\n      .defaultAvatar {\n        width: 120px;\n        height: 120px;\n      }\n    }\n\n    .info {\n      display: flex;\n      flex-direction: column;\n      row-gap: 10px;\n\n      .firstName {\n        font-weight: 500;\n        font-size: 24px;\n        line-height: 29px;\n      }\n\n      .phoneNumber,\n      .email {\n        font-weight: 400;\n        font-size: 16px;\n        line-height: 26px;\n        color: rgba(67, 69, 79, 1);\n      }\n    }\n  }\n\n  .statistics {\n    margin-top: 80px;\n\n    h2 {\n      font-weight: 500;\n      font-size: 24px;\n      line-height: 29px;\n      margin-bottom: 30px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"profilePage": `ProfilePage_profilePage__Em2eZ`,
	"profile": `ProfilePage_profile__hesWn`,
	"image": `ProfilePage_image__Dsnzk`,
	"avatar": `ProfilePage_avatar__axjph`,
	"defaultAvatar": `ProfilePage_defaultAvatar__4nckz`,
	"info": `ProfilePage_info__5Yg37`,
	"firstName": `ProfilePage_firstName__Cy1Zj`,
	"phoneNumber": `ProfilePage_phoneNumber__PqFut`,
	"email": `ProfilePage_email__VcWJz`,
	"statistics": `ProfilePage_statistics__Y--mQ`
};
export default ___CSS_LOADER_EXPORT___;
