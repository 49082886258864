import React from "react";
import { fetchUser } from "../redux/slices/userSlice";
import { useAppDispatch, useAppSelector } from "./redux.hooks";

export const useUser = () => {
  const dispatch = useAppDispatch();
  const { user, isAuthenticated, status, error } = useAppSelector((state) => state.userSlice);

  React.useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  return { user, isAuthenticated, status, error };
};
