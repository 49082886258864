// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Statistic_statistic__BnpQD {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Statistic/Statistic.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,qCAAA;EACA,gBAAA;AACF","sourcesContent":[".statistic {\n  display: grid;\n  grid-template-columns: repeat(4, 1fr);\n  column-gap: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statistic": `Statistic_statistic__BnpQD`
};
export default ___CSS_LOADER_EXPORT___;
