import React from "react";
import styles from "./Loading.module.scss";
import { LogoSVG } from "../../../assets/icons/globals.icons";

export const Loading = (): JSX.Element => {
  return (
    <div className={styles.loading}>
      <LogoSVG className={styles.logo} />
    </div>
  );
};
