import React from 'react';
import styles from './LoadingAnimationWave.module.scss'


export const LoadingAnimationWave = (): JSX.Element  => {
  return (
    <div className={styles.waveWrapper}>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

