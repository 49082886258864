// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Container_container__VRrje {
  width: 100%;
  max-width: 1210px;
  margin: 0 auto;
  padding: 0 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Container/Container.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,cAAA;EACA,eAAA;AACF","sourcesContent":[".container {\n  width: 100%;\n  max-width: 1210px;\n  margin: 0 auto;\n  padding: 0 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `Container_container__VRrje`
};
export default ___CSS_LOADER_EXPORT___;
