import React from "react";
import styles from "./InfoBlock.module.scss";
import cn from "classnames";

interface IProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  number: number;
  title: string;
  description: string;
  appearance: "benefit" | "warn";
}

export const InfoBlock = ({
  number,
  title,
  description,
  appearance,
  className,
  ...props
}: IProps): JSX.Element => {
  return (
    <div
      className={cn(styles.infoBlock, className, {
        [styles.benefit]: appearance === "benefit",
        [styles.warn]: appearance === "warn",
      })}
      {...props}>
      <div className={styles.number}>{number}</div>
      <div className={styles.info}>
        <h3>{title}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};
