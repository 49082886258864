export function getTokenCookie() {
  if (typeof window !== "undefined") {
    const value = `${document.cookie}`;
    const regex = /access=([^;]+)/;
    const jwt = value?.match(regex)
    if (jwt) return jwt[1]
  }
}

export function setCookie(cName: string, cValue: string, expDays: number) {
  let date = new Date();
  date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie = cName + "=" + cValue + "; " + expires + ";path=/";
}

export function clearCookies() {
  if (typeof window !== "undefined") {
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  }
}
