import React from "react";
import styles from "./TransactionCreatePage.module.scss";
import { Form, Input, Button, Balance } from "../../index.components";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import transactionsService from "../../../services/transactionsService";
import { ITransactionCreateForm } from "../../../@types/models/transactions.types";
import { useAppSelector } from "../../../hooks/redux.hooks";


export const TransactionCreatePage = (): JSX.Element => {
  const initialValues: ITransactionCreateForm = {
    notifyRecipientEmail: "",
  };

  const navigate = useNavigate();

  const { user } = useAppSelector((state) => state.userSlice);

  const validationSchema = yup.object({
    notifyRecipientEmail: yup
      .string()
      .email("Неверный формат почты")
      .required("Введите почту")
      .test(
        "is-not-user-email",
        "Нельзя отправлять приглашение самому себе",
        function (value) {
          return value !== user?.email;
        }
      ),
  });

  const formik = useFormik({
    initialValues,
    onSubmit: async ({ notifyRecipientEmail }) => {
      try {
        const data = await transactionsService.handleCreateTransaction({
          notifyRecipientEmail,
        });

        navigate(`../transaction/${data.slug}/file`);

        toast.success("Приглашение успешно отправлено");
      } catch (error) {
        toast.error("Непредвиденная ошибка");
      }
    },
    validationSchema,
  });

  return (
    <div className={styles.transactionCreatePage}>
      <h1>Новая связь</h1>
      <Balance />
      <Form className={styles.form} onSubmit={formik.handleSubmit}>
        <Input
          id="notifyRecipientEmail"
          type="email"
          placeholder="Введите почту вашего партнера"
          label="Электронная почта вашего партнера"
          errorMessage={
            formik.touched.notifyRecipientEmail &&
            formik.errors.notifyRecipientEmail
              ? formik.errors.notifyRecipientEmail
              : ""
          }
          onChange={formik.handleChange}
        />
        <Button
          className={styles.btn}
          appearance="black"
          width="290px"
          height="55px"
          type="submit"
          isSubmitting={formik.isSubmitting}
          disabled={formik.isSubmitting}
        >
          Отправить приглашение
        </Button>
      </Form>
    </div>
  );
};
