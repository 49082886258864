// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "Manrope", sans-serif;
  font-style: normal;
  src: url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
}
@font-face {
  font-family: "TT Norms Pro", sans-serif;
  font-style: normal;
  src: url("https://fonts.cdnfonts.com/css/tt-norms-pro");
}
html,
body {
  font-family: "Manrope", sans-serif;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

ol,
ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "TT Norms Pro", sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/styles/globals.scss","webpack://./src/styles/_variables.scss"],"names":[],"mappings":"AAEA;EACE,kCAAA;EACA,kBAAA;EACA,0GAAA;AADF;AAIA;EACE,uCAAA;EACA,kBAAA;EACA,uDAAA;AAFF;AAKA;;EAEE,kCChBa;ADaf;;AAMA;EACE,UAAA;EACA,SAAA;EACA,sBAAA;AAHF;;AAMA;EACE,cAAA;EACA,qBAAA;AAHF;;AAMA;;EAEE,gBAAA;AAHF;;AAMA;;;;;;EAME,uCCxCe;ADqCjB","sourcesContent":["@import \"./_variables.scss\";\n\n@font-face {\n  font-family: \"Manrope\", sans-serif;\n  font-style: normal;\n  src: url(\"https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap\");\n}\n\n@font-face {\n  font-family: \"TT Norms Pro\", sans-serif;\n  font-style: normal;\n  src: url(\"https://fonts.cdnfonts.com/css/tt-norms-pro\");\n}\n\nhtml,\nbody {\n  font-family: $primary-font;\n}\n\n* {\n  padding: 0;\n  margin: 0;\n  box-sizing: border-box;\n}\n\na {\n  color: inherit;\n  text-decoration: none;\n}\n\nol,\nul {\n  list-style: none;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  font-family: $secondary-font;\n}\n","$primary-font: \"Manrope\", sans-serif;\n$secondary-font: \"TT Norms Pro\", sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
