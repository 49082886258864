import React from "react";
import styles from "./Uploader.module.scss";
import {
  UploaderSVG,
  CloseSVG,
  ApprovedSVG,
} from "../../../assets/icons/globals.icons";
import cn from "classnames";
import transactionsService from "../../../services/transactionsService";
import { ITransactionFile } from "../../../@types/models/transactions.types";
import {useAppDispatch} from "../../../hooks/redux.hooks";
import {transactionsActions} from "../../../redux/slices/transactionsSlice";

interface Error {
  file: string;
}

interface IProps {
  transactionFile: ITransactionFile | undefined;
  transactionId: number | undefined;
  setError: React.Dispatch<React.SetStateAction<Error | null>>;
  setTransactionFiles: React.Dispatch<
    React.SetStateAction<ITransactionFile[] | null>
  >;
  setDisabledStartButton: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  disabled:boolean
}

export const Uploader = ({
  setError,
  transactionId,
  transactionFile,
  setTransactionFiles,
  setDisabledStartButton,
  disabled
}: IProps) => {
  const dispatch = useAppDispatch()

  const inputRef = React.useRef<HTMLInputElement>(null);

  const [file, setFile] = React.useState({} as File);

  const [currentId, setCurrentId] = React.useState<number>();
  const [key, setKey] = React.useState<number>(0);

  const handleUploaderClick = () => {
    if (transactionFile === undefined && !file.name) {
      inputRef.current!.click();
    }
  };

  const handleFileDrop = async (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const dropedFile = event.dataTransfer.files[0];

    if (
      dropedFile.type === "application/vnd.ms-excel" ||
      dropedFile.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      dropedFile.type === "text/csv"
    ) {
      uploadFile(dropedFile, transactionId);
    }
  };

  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();

    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      uploadFile(selectedFile, transactionId);
    }
  };

  const handleDeleteFile = async () => {
    try {
      if (transactionFile?.id) {
        await transactionsService.handleFileDelete(transactionFile?.id);
      } else {
        await transactionsService.handleFileDelete(currentId);
      }
      setFile({} as File);
      setTransactionFiles(null);
      setKey(key + 1);
      setDisabledStartButton(true)
    } catch (error) {

    }
  };

  const uploadFile = async (file: File, transactionId: number | undefined) => {
    const formData = new FormData();
    const fileName = `${Date.now()}_${file.name}`;
    formData.append("file", file, fileName);
    formData.append("transaction", String(transactionId));

    try {
      dispatch(transactionsActions.setIsLoadingFile(true))
      const response = await transactionsService.handleFileUpload(formData);
      if (response.status === "success") {
        setTimeout(()=> {
          dispatch(transactionsActions.setIsLoadingFile(false))
          setCurrentId(response.data.id);
          setFile(file);
          setError(null);
          setDisabledStartButton(false)
        },1000)
      } else {
        setTimeout(()=> {
        dispatch(transactionsActions.setIsLoadingFile(false))
        setError(response.data);
        setKey(key + 1)
        },1000)
      }
    } catch (error) {
      setKey(key + 1);
      dispatch(transactionsActions.setIsLoadingFile(false))
    }
  };

  return (
    <div
      className={cn(styles.uploader, {
        [styles.upload]: transactionFile === undefined || !file.name,
      })}
      onDrop={handleFileDrop}
      onDragOver={(event) => {
        event.preventDefault();
        event.stopPropagation();
        event.dataTransfer.dropEffect = "copy";
      }}
      onClick={handleUploaderClick}
    >
      <input
        key={key}
        name="file"
        type="file"
        ref={inputRef}
        className={styles.fileInput}
        onChange={handleFileSelect}
        accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <div className={styles.icons}>
        <UploaderSVG
          className={cn(styles.uploaderSvg, {
            [styles.uploaded]: transactionFile || file.name,
          })}
        />
      </div>
      <div className={styles.info}>
        {transactionFile !== undefined || file.name ? (
          <>
            <p className={styles.desc}>Ваш файл загружен</p>
            <div className={styles.file}>
              <p className={styles.name}>
                {transactionFile ? transactionFile.fileName : file.name}
                <ApprovedSVG />
              </p>
              {!disabled && <CloseSVG className={styles.delete} onClick={handleDeleteFile}/>}
            </div>
          </>
        ) : (
          <>
            <p className={styles.desc}>
              Перетащите сюда файл или нажмите, чтобы открыть проводник
            </p>
            <p className={styles.warn}>
              Поддерживаемые форматы: .XLS, .XLSX, .CSV
            </p>
          </>
        )}
      </div>
    </div>
  );
};
