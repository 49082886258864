import React from "react";
import styles from "./SignInPage.module.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import $api from "../../../services/axios";
import { setCookie } from "../../../utils/jwt";
import { toast } from "react-toastify";
import { Input, Button, Form } from "../../index.components";
import { IUserSignInForm } from "../../../@types/models/users.types";
import { useNavigate } from "react-router-dom";
import userService from "../../../services/userService";

const validationSchema = yup.object({
  email: yup.string().email("Неверный формат почты").required("Введите почту"),
  password: yup.string().required("Введите пароль"),
});

export const SignInPage = (): JSX.Element => {
  const initialValues: IUserSignInForm = {
    email: "",
    password: "",
  };

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    onSubmit: async ({ email, password }) => {
      try {
        await userService.signIn({ email, password });

        navigate("/dashboard/profile");

        toast.success("Вы успешно вошли в систему");
      } catch (error) {
        toast.error("Почта или пароль введены неверно");
      }
    },
    validationSchema,
  });

  return (
    <>
      <h1 className={styles.signIn}>Вход</h1>
      <p className={styles.desc}>Для продолжения вам необходимо войти в аккаунт.</p>
      <Form className={styles.form} onSubmit={formik.handleSubmit}>
        <Input
          id="email"
          type="email"
          placeholder="Введите почту"
          label="Электронная почта"
          errorMessage={formik.touched.email && formik.errors.email ? formik.errors.email : ""}
          onChange={formik.handleChange}
        />
        <Input
          id="password"
          type="password"
          placeholder="Введите пароль"
          label="Пароль"
          errorMessage={
            formik.touched.password && formik.errors.password ? formik.errors.password : ""
          }
          onChange={formik.handleChange}
        />
        <Button
          className={styles.btn}
          width="130px"
          height="55px"
          appearance="black"
          isSubmitting={formik.isSubmitting}>
          Войти
        </Button>
      </Form>
      <div className={styles.links}>
        <a href="forgot-password">Забыли пароль?</a>
        <a href="sign-up">Зарегистрироваться</a>
      </div>
    </>
  );
};
