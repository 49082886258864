// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Button_button__w\\+JtY {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  transition: all 0.3s;
  border: none;
  border-radius: 40px;
  cursor: pointer;
}

.Button_black__q6rV5 {
  border: 1px solid #18191f;
  background-color: #18191f;
  color: #fff;
}
.Button_black__q6rV5:hover {
  background-color: #fff;
  color: #18191f;
}
.Button_black__q6rV5:hover svg path {
  fill: black;
}

.Button_white__jozcJ {
  border: 1px solid #18191f;
  background-color: #fff;
}
.Button_white__jozcJ:hover {
  background-color: #18191f;
  color: #fff;
}

.Button_red__qQAdp {
  border: 1px solid #ee1d52;
  background: #ee1d52;
  color: #fff;
}
.Button_red__qQAdp:hover {
  background-color: #fff;
  color: #ee1d52;
}

.Button_submitting__\\+nIF4 {
  opacity: 0.7;
  cursor: not-allowed;
}

.Button_disabled__UyXGk {
  opacity: 0.7;
  cursor: not-allowed;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Button/Button.module.scss"],"names":[],"mappings":"AAEA;EACE,oBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,oBAAA;EACA,YAAA;EACA,mBAAA;EACA,eAAA;AADF;;AAIA;EACE,yBAAA;EACA,yBAAA;EACA,WAAA;AADF;AAGE;EACE,sBAAA;EACA,cAAA;AADJ;AAIM;EACE,WAAA;AAFR;;AAQA;EACE,yBAAA;EACA,sBAAA;AALF;AAOE;EACE,yBAAA;EACA,WAAA;AALJ;;AASA;EACE,yBAAA;EACA,mBAAA;EACA,WAAA;AANF;AAQE;EACE,sBAAA;EACA,cAAA;AANJ;;AAUA;EACE,YAAA;EACA,mBAAA;AAPF;;AAUA;EACE,YAAA;EACA,mBAAA;AAPF","sourcesContent":["@import \"../../../styles/_variables.scss\";\n\n.button {\n  display: inline-flex;\n  justify-content: center;\n  align-items: center;\n  column-gap: 10px;\n  padding: 15px 0;\n  font-size: 18px;\n  font-weight: 500;\n  line-height: 21px;\n  transition: all 0.3s;\n  border: none;\n  border-radius: 40px;\n  cursor: pointer;\n}\n\n.black {\n  border: 1px solid #18191f;\n  background-color: #18191f;\n  color: #fff;\n\n  &:hover {\n    background-color: #fff;\n    color: #18191f;\n\n    svg {\n      path {\n        fill: black;\n      }\n    }\n  }\n}\n\n.white {\n  border: 1px solid #18191f;\n  background-color: #fff;\n\n  &:hover {\n    background-color: #18191f;\n    color: #fff;\n  }\n}\n\n.red {\n  border: 1px solid #ee1d52;\n  background: #ee1d52;\n  color: #fff;\n\n  &:hover {\n    background-color: #fff;\n    color: #ee1d52;\n  }\n}\n\n.submitting {\n  opacity: 0.7;\n  cursor: not-allowed;\n}\n\n.disabled {\n  opacity: 0.7;\n  cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `Button_button__w+JtY`,
	"black": `Button_black__q6rV5`,
	"white": `Button_white__jozcJ`,
	"red": `Button_red__qQAdp`,
	"submitting": `Button_submitting__+nIF4`,
	"disabled": `Button_disabled__UyXGk`
};
export default ___CSS_LOADER_EXPORT___;
