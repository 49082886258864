import React from "react";
import styles from "./ForgotPasswordPage.module.scss";
import { IUserForgotPasswordForm } from "../../../@types/models/users.types";
import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import $api from "../../../services/axios";
import { Button, Form, Input } from "../../index.components";

const validationSchema = yup.object({
  email: yup.string().email("Неверный формат почты").required("Введите почту"),
});

export const ForgotPasswordPage = (): JSX.Element => {
  const initialValues: IUserForgotPasswordForm = {
    email: "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (value) => {
      try {
        await $api.post("auth/users/reset_password/", {
          email: value.email.toLocaleLowerCase(),
        });
        toast.success("На вашу почту отправлено письмо");
      } catch (error) {
        toast.error("Пользователь с указанным email не существует");
      }
    },
    validationSchema,
  });

  return (
    <>
      <h1 className={styles.forgotPassword}>Восстановление доступа</h1>
      <Form className={styles.form} onSubmit={formik.handleSubmit}>
        <Input
          id="email"
          type="email"
          placeholder="Введите почту"
          label="Электронная почта"
          errorMessage={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : ""
          }
          onChange={formik.handleChange}
        />
        <Button
          className={styles.btn}
          width="185px"
          height="55px"
          appearance="black"
          isSubmitting={formik.isSubmitting}
        >
          Отправить
        </Button>
      </Form>
    </>
  );
};
