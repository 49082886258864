// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_wrapper__b32m\\+ {
  display: flex;
  flex-direction: column;
}
.Input_wrapper__b32m\\+ .Input_label__Dz5N0 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: rgb(67, 69, 79);
}
.Input_wrapper__b32m\\+ .Input_input__eXwaT {
  padding-left: 15px;
  margin-top: 5px;
  border: 1px solid #dadcdc;
  border-radius: 10px;
  height: 40px;
}
.Input_wrapper__b32m\\+ .Input_input__eXwaT:focus {
  outline: none;
  border: 2px solid black;
}

.Input_hidden__Q5r1G {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Input/Input.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;AADF;AAGE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AADJ;AAIE;EACE,kBAAA;EACA,eAAA;EACA,yBAAA;EACA,mBAAA;EACA,YAAA;AAFJ;AAII;EACE,aAAA;EACA,uBAAA;AAFN;;AAOA;EACE,aAAA;AAJF","sourcesContent":["@import \"../../../styles/_variables.scss\";\n\n.wrapper {\n  display: flex;\n  flex-direction: column;\n\n  .label {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 26px;\n    color: rgba(67, 69, 79, 1);\n  }\n\n  .input {\n    padding-left: 15px;\n    margin-top: 5px;\n    border: 1px solid #dadcdc;\n    border-radius: 10px;\n    height: 40px;\n\n    &:focus {\n      outline: none;\n      border: 2px solid black;\n    }\n  }\n}\n\n.hidden {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Input_wrapper__b32m+`,
	"label": `Input_label__Dz5N0`,
	"input": `Input_input__eXwaT`,
	"hidden": `Input_hidden__Q5r1G`
};
export default ___CSS_LOADER_EXPORT___;
