import React from "react";
import styles from "./Header.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { Container, Button } from "../../../index.components";
import { LogoSVG, NavSVG, CloseSVG } from "../../../../assets/icons/globals.icons";
import { NavMobile } from "./NavMobile/NavMobile";

export const Header = (): JSX.Element => {
  const [activeNavMob, setActiveNavMob] = React.useState<boolean>(false);

  const navigate = useNavigate();

  return (
    <Container>
      <header className={styles.header}>
        {activeNavMob ? (
          <CloseSVG className={styles.navMobile} onClick={() => setActiveNavMob(false)} />
        ) : (
          <NavSVG className={styles.navMobile} onClick={() => setActiveNavMob(true)} />
        )}
        <div className={styles.navigation}>
          <a
            href="https://datamost.su/"
            className={styles.link}>
            О нас
          </a>
          <a
            href="https://datamost.su/#fact"
            className={styles.link}>
            Как это работает?
          </a>
          <a
            href="https://datamost.su/#tarif"
            className={styles.link}>
            Тарифы
          </a>
        </div>
        <LogoSVG />
        <div className={styles.auth}>
          <Link to={"auth/sign-in"} className={styles.logIn}>
            Войти
          </Link>
          <Button
            width="170px"
            height="40px"
            appearance="black"
            onClick={() => navigate("auth/sign-up")}>
            Регистрация
          </Button>
        </div>
      </header>
      <NavMobile isActive={activeNavMob} />
    </Container>
  );
};
