import React from "react";
import styles from "./Footer.module.scss";
import { Container } from "../../../index.components";
import {
  SocialInstagramSVG,
  SocialWhatsAppSVG,
  SocialTelegramSVG,
} from "../../../../assets/icons/landing.icons";
import { LogoSVG } from "../../../../assets/icons/globals.icons";

export const Footer = (): JSX.Element => {
  return (
    <footer className={styles.footer}>
      <Container className={styles.wrapper}>
        <div className={styles.upper}>
          <LogoSVG className={styles.logo} />
          <div className={styles.navigation}>
            <div className={styles.site}>
              <a
                href="https://datamost.su/"
                className={styles.link}
              >
                О нас
              </a>
              <a
                href="https://datamost.su/#fact"

                className={styles.link}
              >
                Как это работает?
              </a>
              <a
                href="https://datamost.su/#tarif"
                className={styles.link}
              >
                Тарифы
              </a>
            </div>
            <div className={styles.social}>
              <SocialInstagramSVG />
              <SocialWhatsAppSVG />
              <a href="https://t.me/+KQpQ6Ui7MWU1OWVi">
                <SocialTelegramSVG />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.lower}>
          <div className={styles.left}>
            <a href="/policy.pdf" target="_blank">
              Политика конфиденциальности
            </a>
            <a href="/terms_of_service.pdf" target="_blank">
              Оферта
            </a>
          </div>
          <div className={styles.right}>
            <p>© 2023 MOST. Все права защищены</p>
          </div>
        </div>
      </Container>
    </footer>
  );
};
