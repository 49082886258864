// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Form_form__1Ye0b {
  display: grid;
  row-gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Form/Form.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;AACF","sourcesContent":[".form {\n  display: grid;\n  row-gap: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `Form_form__1Ye0b`
};
export default ___CSS_LOADER_EXPORT___;
