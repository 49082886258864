import $api from "./axios";
import {
  ITransactionCreateForm,
  ITransactionSearchParams,
} from "../@types/models/transactions.types";
import axios, { AxiosError } from "axios";
import { getTokenCookie } from "../utils/jwt";
import { toast } from "react-toastify";

const transactionsService = {
  async fetchTransactionsByParams(params: ITransactionSearchParams) {
    try {
      const { data } = await $api.get("transactions_list/", {});
      return data;
    } catch (error) {}
  },

  async fetchTransactionBySlug(slug: string) {
    try {
      const { data } = await $api.get(`transactions/${slug}/`);
      return data;
    } catch (error) {}
  },

  async fetchTransactionFileBySlug(slug: string, userId?: number | undefined) {
    try {
      const { data } = await $api.get("transaction_files/", {
        params: {
          transaction_slug: slug,
          owner: userId,
        },
      });
      return data;
    } catch (error) {}
  },

  async startCompareBySlag(slug: string) {
    try {
      const { data } = await $api.get(`start_compare/`,{
        params: {
          slug: slug,
        },
      });
      toast.success('Вы подтвердили сравнение.')
      return data;
    } catch (error) {
      return error
    }
  },

  async handleCreateTransaction({
    notifyRecipientEmail,
  }: ITransactionCreateForm) {
    try {
      const { data } = await $api.post("transactions/", {
        notifyRecipientEmail: notifyRecipientEmail.toLocaleLowerCase(),
      });
      return data;
    } catch (error) {}
  },

  async handleConfirmationTransaction(
    slug: string,
    status: string,
    recipient: number | undefined
  ) {
    try {
      const { data } = await $api.patch(`transactions/${slug}/`, {
        status: status,
        recipient: recipient,
      });
      return data;
    } catch (error) {}
  },

  async handleUpdateStatusTransaction(slug: string, status: string) {
    try {
      const { data } = await $api.patch(`transactions/${slug}/`, {
        status: status,
      });
      return data;
    } catch (error) {}
  },

  async handlePayment(slug: string) {
    try {
      const { data } = await $api.patch(`transactions/${slug}/`, {
        status: "PAYMENT",
      });
      return {
        data: data,
        status: "success",
      };
    } catch (error) {
      const e = error as AxiosError;
      toast.error("Ошибка в при оплате");
      return {
        data: e.response?.data,
        status: "error",
      };
    }
  },

  async handleUpdateAgreementFile(id: number | undefined, isShare: boolean) {
    try {
      await $api.patch(`transaction_files/${id}/`, {
        isShare: isShare,
      });
    } catch (error) {}
  },

  async handleFileUpload(formData: FormData) {
    try {
      const { data } = await axios({
        method: "post",
        url: `${process.env.REACT_APP_PUBLIC_API_URL}transaction_files/`,
        data: formData,
        headers: {
          "Content-type":
            "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      });
      toast.success("Файл успешно загружен");
      return {
        data: data,
        status: "success",
      };
    } catch (error) {
      const e = error as AxiosError;
      toast.error("Ошибка в загрузке файла");
      return {
        data: e.response?.data,
        status: "error",
      };
    }
  },

  async handleFileDelete(fileId: number | undefined) {
    try {
      await $api.delete(`transaction_files/${fileId}/`);
      toast.success("Файл успешно удален");
    } catch (error) {
      toast.error("Ошибка");
    }
  },

  async deleteTransaction(slug: string) {
    await $api.delete(`transactions/${slug}/`);
  },
};

export default transactionsService;
