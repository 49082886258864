// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__Ub-5P {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
}
.Header_header__Ub-5P .Header_logo__VXZpy {
  margin: 0 auto;
}
.Header_header__Ub-5P .Header_user__UoK0a {
  display: flex;
  align-items: center;
  column-gap: 10px;
  position: relative;
}
.Header_header__Ub-5P .Header_user__UoK0a .Header_userAvatar__QLrVf {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  background-color: black;
  overflow: hidden;
}
.Header_header__Ub-5P .Header_user__UoK0a .Header_userAvatar__QLrVf .Header_avatar__ZKIz7 {
  width: 100%;
  height: 100%;
}
.Header_header__Ub-5P .Header_user__UoK0a .Header_arrow__kMBFp {
  cursor: pointer;
}
.Header_header__Ub-5P .Header_user__UoK0a .Header_logout__vS9RQ {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background-color: #fff;
  padding: 15px;
  border: 1px solid rgba(102, 102, 102, 0.3);
  border-radius: 15px;
  width: 140px;
  height: 60px;
  top: 35px;
  right: 0;
  cursor: pointer;
}
.Header_header__Ub-5P .Header_user__UoK0a .Header_logout__vS9RQ p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: rgb(67, 69, 79);
}
.Header_header__Ub-5P .Header_user__UoK0a .Header_logout__vS9RQ:hover {
  background-color: rgba(102, 102, 102, 0.3);
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/DashboardLayout/Header/Header.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;AACF;AACE;EACE,cAAA;AACJ;AAEE;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,kBAAA;AAAJ;AAEI;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,uBAAA;EACA,gBAAA;AAAN;AAEM;EACE,WAAA;EACA,YAAA;AAAR;AAII;EACE,eAAA;AAFN;AAKI;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,sBAAA;EACA,aAAA;EACA,0CAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;EACA,SAAA;EACA,QAAA;EACA,eAAA;AAHN;AAKM;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AAHR;AAMM;EACE,0CAAA;AAJR","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 15px 0;\n\n  .logo {\n    margin: 0 auto;\n  }\n\n  .user {\n    display: flex;\n    align-items: center;\n    column-gap: 10px;\n    position: relative;\n\n    .userAvatar {\n      display: flex;\n      align-items: center;\n      justify-content: center;\n      border-radius: 50%;\n      width: 45px;\n      height: 45px;\n      background-color: black;\n      overflow: hidden;\n\n      .avatar {\n        width: 100%;\n        height: 100%;\n      }\n    }\n\n    .arrow {\n      cursor: pointer;\n    }\n\n    .logout {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n      position: absolute;\n      background-color: #fff;\n      padding: 15px;\n      border: 1px solid rgba(102, 102, 102, 0.3);\n      border-radius: 15px;\n      width: 140px;\n      height: 60px;\n      top: 35px;\n      right: 0;\n      cursor: pointer;\n\n      p {\n        font-weight: 400;\n        font-size: 16px;\n        line-height: 26px;\n        color: rgba(67, 69, 79, 1);\n      }\n\n      &:hover {\n        background-color: rgba(102, 102, 102, 0.3);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__Ub-5P`,
	"logo": `Header_logo__VXZpy`,
	"user": `Header_user__UoK0a`,
	"userAvatar": `Header_userAvatar__QLrVf`,
	"avatar": `Header_avatar__ZKIz7`,
	"arrow": `Header_arrow__kMBFp`,
	"logout": `Header_logout__vS9RQ`
};
export default ___CSS_LOADER_EXPORT___;
