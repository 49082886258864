import React from "react";
import styles from "./TransactionFilePage.module.scss";
import {Balance, Uploader, Input, Button} from "../../index.components";
import {
  InfoCircleSVG,
  AlertSVG,
  TableSVG,
} from "../../../assets/icons/globals.icons";
import {useAppSelector} from "../../../hooks/redux.hooks";
import {useNavigate, useParams} from "react-router-dom";
import {
  ITransaction,
  ITransactionFile,
} from "../../../@types/models/transactions.types";
import transactionsService from "../../../services/transactionsService";
import {toast} from "react-toastify";

interface ErrorFile {
  file: string;
}

interface ErrorCompare {
  title: string;
  desc: string;
}

export const TransactionFilePage = (): JSX.Element => {
  const {slug} = useParams();
  const navigate = useNavigate();

  const {user} = useAppSelector((state) => state.userSlice);
  const [transaction, setTransaction] = React.useState<ITransaction | null>(
    null
  );
  const [transactionFiles, setTransactionFiles] = React.useState<
    ITransactionFile[] | null
  >(null);
  const [errorFile, setErrorFile] = React.useState<ErrorFile | null>(null);
  const [errorCompare, setErrorCompare] = React.useState<ErrorCompare | null>();
  const [errorPayment, setErrorPayment] = React.useState<string | null>(null);
  const [isDisabledStartButton, setDisabledStartButton] = React.useState<boolean>(true);
  const [isConfirmed,setIsConfirmed] = React.useState<boolean>(false)
  const [isSubmitting,setIsSubmitting] = React.useState<boolean>(false)

  const socket = new WebSocket(`wss://datamost.pro/ws/transaction/compare_data/${user!.id}/${slug}/`)


  React.useEffect(() => {
    const fetchTransaction = async () => {
      const data = await transactionsService.fetchTransactionBySlug(slug!);
      if(data.sender.email === user?.email){
        setIsConfirmed(data.isSenderConfirmed)
      } else {
        setIsConfirmed(data.isRecipientConfirmed)
      }
      setTransaction(data);
    };

    const fetchTransactionFile = async () => {
      const data = await transactionsService.fetchTransactionFileBySlug(
        slug!,
        user?.id
      );
      if (data && data[0]) {
        setDisabledStartButton(false)
      }
      setTransactionFiles(data);
    };

    fetchTransaction();
    fetchTransactionFile();
  }, [slug]);

  React.useEffect(() => {

    socket.onmessage = function (event) {
      let eventData
      try {
        eventData = JSON.parse(event.data)
      } catch (e) {
      }
      switch (eventData?.message?.status) {
        case "CONFIRMATION":
          toast.success("Приглашенный пользователь подтвердил сопоставление");
          setErrorCompare(null)
          setDisabledStartButton(false)
          break
        case "PAYMENT":
          break
        case "COMPARE_DB":
          navigate(`../transaction/${slug}/result`);
          toast.success("Сравнение успешно завершено");
          break
      }
    }

  }, [slug])

  const handleCompare = async () => {
    try {
      setIsSubmitting(true)
      const filesCompare = await transactionsService.fetchTransactionFileBySlug(slug!);
      console.log(filesCompare)
      if (filesCompare?.length === 2) {
        setErrorCompare(null);
        const data = await transactionsService.startCompareBySlag(slug!);
        if (data.message === "sender has enough money") {
          setErrorPayment("У отправителя не хватает средств")
          toast.error('Нехвататет средств')
        } else if (data.message === "recipient has enough money") {
          setErrorPayment("У приглашенного пользователя не хватает средств")
          toast.error('Нехвататет средств')
        } else {
          toast.success('Вы подтвердили сравнение.')
          setDisabledStartButton(true);
          setIsConfirmed(true)
        }
      } else {
        setErrorCompare({
          title: "Ошибка при загрузке данных на стороне партнера",
          desc: "Дождитесь, пока партнер загрузит правильные данные",
        });
      }
    } catch (e) {
      toast.error('Непридвиденная ошибка')
      setErrorCompare({
        title: "Ошибка",
        desc: "Попробуйте повторить позже",
      });
      setDisabledStartButton(false)
    } finally {
      setIsSubmitting(false)
    }
  };

  return (
    <div className={styles.transactionFilePage}>
      <h1>Новая связь</h1>
      <Balance/>
      <div className={styles.partner}>
        {transaction?.sender.email === user?.email ? (
          <>
            <Input
              type="email"
              placeholder="Введите почту партнера"
              label="Электронная почта вашего партнера"
              disabled={true}
              defaultValue={transaction?.notifyRecipientEmail}
            />
            <p>Приглашение отправлено</p>
          </>
        ) : (
          <Input
            type="email"
            placeholder="Введите почту партнера"
            label="Электронная почта вашего партнера"
            disabled={true}
            defaultValue={transaction?.sender.email}
          />
        )}
      </div>
      <div className={styles.uploadFile}>
        <h2>
          <InfoCircleSVG
            className={styles.infoSvg}
          />
          Важно
        </h2>
        <p className={styles.desc}>
          Файл таблицы должен быть в форматах <span>xls/xlsx/csv</span>.
        </p>
        <p className={styles.desc}>
          <span>Первый столбец</span> должен содержать{" "}
          <span>ИНН/телефон/email, второй — Данные.</span>
          Столбцы должны иметь соответствующие заголовки.
        </p>
        <div className={styles.svg}>
          <TableSVG/>
        </div>
      </div>
      <Uploader
        setTransactionFiles={setTransactionFiles}
        transactionFile={transactionFiles?.[0]}
        setError={setErrorFile}
        transactionId={transaction?.id}
        setDisabledStartButton={setDisabledStartButton}
        disabled={isConfirmed}
      />
      {errorFile ? (
        <div className={styles.alert}>
          <AlertSVG/>
          <div className={styles.error}>
            <p className={styles.title}>Ошибка в загруженном файле</p>
            <p className={styles.desc}>{errorFile.file} </p>
          </div>
        </div>
      ) : null}

      {errorCompare ? (
        <div className={styles.alert}>
          <AlertSVG/>
          <div className={styles.error}>
            <p className={styles.title}>{errorCompare.title}</p>
            <p className={styles.desc}>{errorCompare.desc} </p>
          </div>
        </div>
      ) : null}

      {errorPayment ? (
        <div className={styles.alert}>
          <AlertSVG/>
          <div className={styles.error}>
            <p className={styles.title}>Для новой связи пополните баланс</p>
            <p className={styles.desc}>{errorPayment}</p>
          </div>
        </div>
      ) : null}

      <Button
        className={styles.btn}
        width="270px"
        height="55px"
        appearance="black"
        onClick={handleCompare}
        isSubmitting={isSubmitting}
        disabled={isDisabledStartButton || isConfirmed}
      >
        Начать сопоставление
      </Button>
    </div>
  );
};
