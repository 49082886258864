// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignInPage_signIn__Fwbbb {
  font-weight: 500;
  font-size: 40px;
  line-height: 113.7%;
  text-align: center;
}
@media (max-width: 400px) {
  .SignInPage_signIn__Fwbbb {
    font-size: 30px;
  }
}

.SignInPage_desc__43KPX {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: rgb(67, 69, 79);
  text-align: center;
  margin-top: 10px;
}

.SignInPage_form__FPTg6 {
  margin-top: 40px;
}
.SignInPage_form__FPTg6 .SignInPage_btn__ea27b {
  margin: 0 auto;
}

.SignInPage_links__J\\+QQz {
  display: flex;
  justify-content: space-evenly;
  margin-top: 40px;
}
.SignInPage_links__J\\+QQz a {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: rgb(67, 69, 79);
}
.SignInPage_links__J\\+QQz a:hover {
  color: black;
}
@media (max-width: 780px) {
  .SignInPage_links__J\\+QQz {
    justify-content: space-between;
  }
}
@media (max-width: 556px) {
  .SignInPage_links__J\\+QQz a {
    font-size: 14px;
  }
}
@media (max-width: 360px) {
  .SignInPage_links__J\\+QQz a {
    font-size: 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/pages/SignInPage/SignInPage.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACE;EACE;IACE,eAAA;EACJ;AACF;;AAGA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;AAEE;EACE,cAAA;AAAJ;;AAIA;EACE,aAAA;EACA,6BAAA;EACA,gBAAA;AADF;AAGE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AADJ;AAGI;EACE,YAAA;AADN;AAKE;EACE;IACE,8BAAA;EAHJ;AACF;AAME;EACE;IACE,eAAA;EAJJ;AACF;AAOE;EACE;IACE,eAAA;EALJ;AACF","sourcesContent":[".signIn {\n  font-weight: 500;\n  font-size: 40px;\n  line-height: 113.7%;\n  text-align: center;\n\n  @media (max-width: 400px) {\n    & {\n      font-size: 30px;\n    }\n  }\n}\n\n.desc {\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 26px;\n  color: rgba(67, 69, 79, 1);\n  text-align: center;\n  margin-top: 10px;\n}\n\n.form {\n  margin-top: 40px;\n\n  .btn {\n    margin: 0 auto;\n  }\n}\n\n.links {\n  display: flex;\n  justify-content: space-evenly;\n  margin-top: 40px;\n\n  a {\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 26px;\n    color: rgba(67, 69, 79, 1);\n\n    &:hover {\n      color: black;\n    }\n  }\n\n  @media (max-width: 780px) {\n    & {\n      justify-content: space-between;\n    }\n  }\n\n  @media (max-width: 556px) {\n    a {\n      font-size: 14px;\n    }\n  }\n\n  @media (max-width: 360px) {\n    a {\n      font-size: 12px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signIn": `SignInPage_signIn__Fwbbb`,
	"desc": `SignInPage_desc__43KPX`,
	"form": `SignInPage_form__FPTg6`,
	"btn": `SignInPage_btn__ea27b`,
	"links": `SignInPage_links__J+QQz`
};
export default ___CSS_LOADER_EXPORT___;
