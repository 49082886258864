import React from "react";
import { Outlet } from "react-router-dom";
import { Container } from "../../index.components";
import { Header } from "./Header/Header";
import styles from "./AuthLayout.module.scss";

export const AuthLayout = (): JSX.Element => {
  return (
    <div>
      <Header />
      <Container>
        <div className={styles.wrapper}>
          <Outlet />
        </div>
      </Container>
    </div>
  );
};
