// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransactionCreatePage_transactionCreatePage__7ZNKQ h1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 113.7%;
  margin-bottom: 35px;
}
.TransactionCreatePage_transactionCreatePage__7ZNKQ .TransactionCreatePage_form__FFLtk {
  margin-top: 35px;
  max-width: 550px;
}
.TransactionCreatePage_transactionCreatePage__7ZNKQ .TransactionCreatePage_form__FFLtk .TransactionCreatePage_btn__yK4VJ {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/TransactionCreatePage/TransactionCreatePage.module.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,mBAAA;AAAJ;AAGE;EACE,gBAAA;EACA,gBAAA;AADJ;AAGI;EACI,gBAAA;AADR","sourcesContent":[".transactionCreatePage {\n  h1 {\n    font-weight: 500;\n    font-size: 40px;\n    line-height: 113.7%;\n    margin-bottom: 35px;\n  }\n\n  .form {\n    margin-top: 35px;\n    max-width: 550px;\n\n    .btn {\n        margin-top: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transactionCreatePage": `TransactionCreatePage_transactionCreatePage__7ZNKQ`,
	"form": `TransactionCreatePage_form__FFLtk`,
	"btn": `TransactionCreatePage_btn__yK4VJ`
};
export default ___CSS_LOADER_EXPORT___;
