import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import styles from "./LandingLayout.module.scss";
import { Header } from "./Header/Header";
import { Footer } from "./Footer/Footer";

export const LandingLayout = (): JSX.Element => {

  return (
    <div className={styles.wrapper}>
      <Header />
      <div className={styles.content}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};
