import React from "react";
import styles from "./StatisticItem.module.scss";
import cn from "classnames";

interface IProps {
  title: string;
  info: number;
  appearance: "primary" | "blue" | "orange";
}

export const StatisticItem = ({ title, info, appearance }: IProps): JSX.Element => {
  return (
    <div className={styles.statisticItem}>
      <p className={styles.title}>{title}</p>
      <p
        className={cn(styles.info, {
          [styles.blue]: appearance === "blue",
          [styles.primary]: appearance === "primary",
          [styles.orange]: appearance === "orange",
        })}>
        {info}
      </p>
    </div>
  );
};
