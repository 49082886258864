// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loading_lds__q6EhZ {
  display: inline-block;
  position: relative;
  width: 15px;
  height: 15px;
}

.Loading_lds__q6EhZ div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  margin: 0;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: Loading_lds__q6EhZ 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #898989 transparent transparent;
}

.Loading_lds__q6EhZ div:nth-child(1) {
  animation-delay: -0.45s;
}

.Loading_lds__q6EhZ div:nth-child(2) {
  animation-delay: -0.3s;
}

.Loading_lds__q6EhZ div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes Loading_lds__q6EhZ {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/common/Button/Loading/Loading.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACF;;AAEA;EACE,sBAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,SAAA;EACA,sBAAA;EACA,kBAAA;EACA,wEAAA;EACA,6CAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,sBAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE;IACE,uBAAA;EACF;EAEA;IACE,yBAAA;EAAF;AACF","sourcesContent":[".lds {\n  display: inline-block;\n  position: relative;\n  width: 15px;\n  height: 15px;\n}\n\n.lds div {\n  box-sizing: border-box;\n  display: block;\n  position: absolute;\n  width: 15px;\n  height: 15px;\n  margin: 0;\n  border: 2px solid #fff;\n  border-radius: 50%;\n  animation: lds 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;\n  border-color: #898989 transparent transparent;\n}\n\n.lds div:nth-child(1) {\n  animation-delay: -0.45s;\n}\n\n.lds div:nth-child(2) {\n  animation-delay: -0.3s;\n}\n\n.lds div:nth-child(3) {\n  animation-delay: -0.15s;\n}\n\n@keyframes lds {\n  0% {\n    transform: rotate(0deg);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"lds": `Loading_lds__q6EhZ`
};
export default ___CSS_LOADER_EXPORT___;
