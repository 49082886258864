import React from "react";
import styles from "./Button.module.scss";
import cn from "classnames";
import { AddButtonSVG } from "../../../assets/icons/globals.icons";
import { Loading } from "./Loading/Loading";

interface IProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  width: string;
  height: string;
  appearance: "black" | "white" | "grey" | "red";
  isSubmitting?: boolean;
  addBtn?: boolean;
}

export const Button = ({
  width,
  height,
  appearance,
  isSubmitting,
  addBtn,
  children,
  className,
  disabled,
  ...props
}: IProps): JSX.Element => {
  return (
    <button
      className={cn(styles.button, className, {
        [styles.black]: appearance === "black",
        [styles.white]: appearance === "white",
        [styles.grey]: appearance === "grey",
        [styles.red]: appearance === "red",
        [styles.submitting]: isSubmitting,
        [styles.disabled]: disabled,
      })}
      disabled={disabled}
      style={{ width: width, height: height }}
      {...props}
    >
      {addBtn ? <AddButtonSVG /> : null}
      {children}
      {isSubmitting && <Loading />}
    </button>
  );
};
