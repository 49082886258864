import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import $api from "../../../services/axios";

export const ActivateUserPage = () => {
  const navigate = useNavigate();
  const [activationParams] = useSearchParams();

  const uid = activationParams.get("uid");
  const token = activationParams.get("token");

  const activate = async () => {
    try {
      await $api.post("auth/users/activation/", { uid, token });
      toast.success("Учетная запись активирована");
    } catch (error) {
      toast.error("Непредвиденная ошибка");
    }
  };

  React.useEffect(() => {
    if (uid && token) {
      activate();
      navigate("/auth/sign-in");
    }
  });

  return <></>;
};
