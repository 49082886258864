import React from "react";
import styles from "./Input.module.scss";
import { ErrorMessage } from "./ErrorMessage/ErrorMessage";
import cn from "classnames";

interface IProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label: string;
  errorMessage?: string;
}

export const Input = ({
  type,
  label,
  errorMessage,
  ...props
}: IProps): JSX.Element => {
  return (
    <div
      className={cn(styles.wrapper, {
        [styles.hidden]: type === "hidden",
      })}
    >
      <label htmlFor="" className={styles.label}>
        {label}
        {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
      </label>{" "}
      <input className={styles.input} type={type} {...props} />
    </div>
  );
};
