import React from "react";
import styles from "./ErrorMessage.module.scss";

interface IProps {
  message: string;
}

export const ErrorMessage = ({ message }: IProps): JSX.Element => {
  return <span className={styles.message}>{message}</span>;
};
