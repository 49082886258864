import React from "react";
import styles from "./ProfilePage.module.scss";
import { useAppSelector } from "../../../hooks/redux.hooks";
import { DefaultAvatarSVG } from "../../../assets/icons/globals.icons";
import { Statistic } from "../../index.components";

export const ProfilePage = (): JSX.Element => {
  const { user } = useAppSelector((state) => state.userSlice);

  return (
    <div className={styles.profilePage}>
      <h1>Профиль</h1>
      <div className={styles.profile}>
        <div className={styles.image}>
          {user?.avatar ? (
            <img className={styles.avatar} src={user.avatar} alt="avatar" />
          ) : (
            <DefaultAvatarSVG className={styles.defaultAvatar} />
          )}
        </div>
        <div className={styles.info}>
          <p className={styles.firstName}>
            {user?.firstName} {user?.lastName}
          </p>
          <p className={styles.phoneNumber}>{user?.phoneNumber}</p>
          <p className={styles.email}>{user?.email}</p>
        </div>
      </div>
      <div className={styles.statistics}>
        <h2>Баланс и статистика</h2>
        <Statistic />
      </div>
    </div>
  );
};
