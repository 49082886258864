import { IUserSignInForm, IUserSignUpForm } from "../@types/models/users.types";
import { setCookie } from "../utils/jwt";
import $api from "./axios";

const userService = {
  async fetchUser() {
    const { data } = await $api.get("auth/users/me/");
    return data;
  },

  async signIn({ email, password }: IUserSignInForm) {
    const { data } = await $api.post("auth/jwt/create/", {
      email: email.toLocaleLowerCase(),
      password,
    });

    setCookie("access", data.access, 999999);
    setCookie("refresh", data.refresh, 999999);
  },

  async signUp(values: IUserSignUpForm) {
    const { data } = await $api.post("auth/users/", {
      ...values,
      email: values.email.toLocaleLowerCase(),
    });

    return data;
  },

  async activate(uid: string, token: string) {
    await $api.post("auth/users/activation/", { uid, token });
  },

  async userExists(email: string) {
    const { data } = await $api.get("auth/user-exists/", {
      params: {
        email: email,
      },
    });
    return data;
  },
};

export default userService;
