import { createBrowserRouter } from "react-router-dom";
import {
  LandingLayout,
  LandingPage,
  DashboardLayout,
  AuthLayout,
  SignInPage,
  SignUpPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  Error404Page,
  ActivateUserPage,
  ProfilePage,
  TransactionListPage,
  TransactionFilePage,
  TransactionCreatePage,
  TransactionResultPage,
  TransactionConfirmationPage,
  PrivateRoute,
} from "./components/index.components";


const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingLayout />,
    children: [
      {
        path: "",
        element: <LandingPage />,
      },
    ],
    errorElement: <Error404Page />,
  },
  {
    path: "/auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignInPage />,
      },
      {
        path: "sign-up",
        element: <SignUpPage />,
      },
      {
        path: "activate-user",
        element: <ActivateUserPage />,
      },
      {
        path: "forgot-password",
        element: <ForgotPasswordPage />,
      },
      {
        path: "reset-password",
        element: <ResetPasswordPage />,
      },
    ],
    errorElement: <Error404Page />,
  },
  {
    path: "/dashboard",
    element: <PrivateRoute />,
    children: [
      {
        path: "",
        element: <DashboardLayout />,
        children: [
          {
            path: "profile",
            element: <ProfilePage />,
          },
          {
            path: "transactions",
            element: <TransactionListPage />,
          },
          {
            path: "transaction-create",
            element: <TransactionCreatePage />,
          },
          {
            path: "transaction/:slug/confirmation",
            element: <TransactionConfirmationPage />,
          },
          {
            path: "transaction/:slug/file",
            element: <TransactionFilePage />,
          },
          {
            path: "transaction/:slug/result",
            element: <TransactionResultPage />,
          },
        ],
      },
    ],
    errorElement: <Error404Page />,
  },
]);

export default router;
