// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavMobile_navMobile__Fnd2N {
  display: none;
  position: absolute;
  top: 70px;
  left: 0;
  z-index: 999;
  background-color: #fff;
  width: 100vw;
  transition: all 0.3s;
}
.NavMobile_navMobile__Fnd2N ul {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.NavMobile_navMobile__Fnd2N ul li {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: rgb(67, 69, 79);
  padding: 20px 0;
  cursor: pointer;
}
.NavMobile_navMobile__Fnd2N ul li:hover {
  color: black;
}

.NavMobile_active__Yj2X6 {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/LandingLayout/Header/NavMobile/NavMobile.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,SAAA;EACA,OAAA;EACA,YAAA;EACA,sBAAA;EACA,YAAA;EACA,oBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACI;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,eAAA;EACA,eAAA;AACN;AACM;EACE,YAAA;AACR;;AAKA;EACE,cAAA;AAFF","sourcesContent":[".navMobile {\n  display: none;\n  position: absolute;\n  top: 70px;\n  left: 0;\n  z-index: 999;\n  background-color: #fff;\n  width: 100vw;\n  transition: all 0.3s;\n\n  ul {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    li {\n      font-weight: 700;\n      font-size: 14px;\n      line-height: 19px;\n      color: rgba(67, 69, 79, 1);\n      padding: 20px 0;\n      cursor: pointer;\n\n      &:hover {\n        color: black;\n      }\n    }\n  }\n}\n\n.active {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navMobile": `NavMobile_navMobile__Fnd2N`,
	"active": `NavMobile_active__Yj2X6`
};
export default ___CSS_LOADER_EXPORT___;
