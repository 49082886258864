// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatisticItem_statisticItem__-f3vG {
  display: grid;
  row-gap: 50px;
  padding: 15px;
  background-color: rgb(247, 249, 250);
  border-radius: 15px;
  max-width: 230px;
}
.StatisticItem_statisticItem__-f3vG .StatisticItem_title__H27va {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: rgb(67, 69, 79);
}
.StatisticItem_statisticItem__-f3vG .StatisticItem_info__eMkN2 {
  font-weight: 500;
  font-size: 40px;
  line-height: 113.7%;
}

.StatisticItem_blue__RDr9U {
  color: rgb(0, 41, 255);
}

.StatisticItem_primary__guefP {
  color: rgb(84, 233, 184);
}

.StatisticItem_orange__5exEr {
  color: rgb(255, 160, 72);
}`, "",{"version":3,"sources":["webpack://./src/components/common/Statistic/StatisticItem/StatisticItem.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,aAAA;EACA,oCAAA;EACA,mBAAA;EACA,gBAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AACJ;AAEE;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;AAAJ;;AAIA;EACE,sBAAA;AADF;;AAIA;EACE,wBAAA;AADF;;AAIA;EACE,wBAAA;AADF","sourcesContent":[".statisticItem {\n  display: grid;\n  row-gap: 50px;\n  padding: 15px;\n  background-color: rgba(247, 249, 250, 1);\n  border-radius: 15px;\n  max-width: 230px;\n\n  .title {\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 26px;\n    color: rgba(67, 69, 79, 1);\n  }\n\n  .info {\n    font-weight: 500;\n    font-size: 40px;\n    line-height: 113.7%;\n  }\n}\n\n.blue {\n  color: rgba(0, 41, 255, 1);\n}\n\n.primary {\n  color: rgba(84, 233, 184, 1);\n}\n\n.orange {\n  color: rgba(255, 160, 72, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"statisticItem": `StatisticItem_statisticItem__-f3vG`,
	"title": `StatisticItem_title__H27va`,
	"info": `StatisticItem_info__eMkN2`,
	"blue": `StatisticItem_blue__RDr9U`,
	"primary": `StatisticItem_primary__guefP`,
	"orange": `StatisticItem_orange__5exEr`
};
export default ___CSS_LOADER_EXPORT___;
