// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResetPasswordPage_resetPassword__iM\\+yA {
  font-weight: 500;
  font-size: 40px;
  line-height: 113.7%;
  text-align: center;
}
@media (max-width: 400px) {
  .ResetPasswordPage_resetPassword__iM\\+yA {
    font-size: 30px;
  }
}

.ResetPasswordPage_form__6JIgw {
  margin-top: 40px;
}
.ResetPasswordPage_form__6JIgw .ResetPasswordPage_rules__1NYfr {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 15px;
  margin-bottom: 20px;
}
.ResetPasswordPage_form__6JIgw .ResetPasswordPage_rules__1NYfr .ResetPasswordPage_item__zAY\\+7 {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.ResetPasswordPage_form__6JIgw .ResetPasswordPage_rules__1NYfr .ResetPasswordPage_item__zAY\\+7 .ResetPasswordPage_circle__xDgDi {
  width: 8px;
  height: 8px;
  background: linear-gradient(0deg, #54e9b8, #54e9b8), #ffffff;
  border-radius: 50%;
}
.ResetPasswordPage_form__6JIgw .ResetPasswordPage_rules__1NYfr .ResetPasswordPage_item__zAY\\+7 p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgb(67, 69, 79);
}
@media (max-width: 1040px) {
  .ResetPasswordPage_form__6JIgw .ResetPasswordPage_rules__1NYfr {
    grid-template-columns: 1fr;
    row-gap: 5px;
  }
}
.ResetPasswordPage_form__6JIgw .ResetPasswordPage_btn__gkinW {
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/ResetPasswordPage/ResetPasswordPage.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACE;EACE;IACE,eAAA;EACJ;AACF;;AAGA;EACE,gBAAA;AAAF;AAEE;EACE,aAAA;EACA,qCAAA;EACA,aAAA;EACA,mBAAA;AAAJ;AAEI;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AAAN;AAEM;EACE,UAAA;EACA,WAAA;EACA,4DAAA;EACA,kBAAA;AAAR;AAGM;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AADR;AAKI;EACE;IACE,0BAAA;IACA,YAAA;EAHN;AACF;AAOE;EACE,cAAA;AALJ","sourcesContent":[".resetPassword {\n  font-weight: 500;\n  font-size: 40px;\n  line-height: 113.7%;\n  text-align: center;\n\n  @media (max-width: 400px) {\n    & {\n      font-size: 30px;\n    }\n  }\n}\n\n.form {\n  margin-top: 40px;\n\n  .rules {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    row-gap: 15px;\n    margin-bottom: 20px;\n\n    .item {\n      display: flex;\n      align-items: center;\n      column-gap: 10px;\n\n      .circle {\n        width: 8px;\n        height: 8px;\n        background: linear-gradient(0deg, #54e9b8, #54e9b8), #ffffff;\n        border-radius: 50%;\n      }\n\n      p {\n        font-weight: 400;\n        font-size: 12px;\n        line-height: 16px;\n        color: rgba(67, 69, 79, 1);\n      }\n    }\n\n    @media (max-width: 1040px) {\n      & {\n        grid-template-columns: 1fr;\n        row-gap: 5px;\n      }\n    }\n  }\n\n  .btn {\n    margin: 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resetPassword": `ResetPasswordPage_resetPassword__iM+yA`,
	"form": `ResetPasswordPage_form__6JIgw`,
	"rules": `ResetPasswordPage_rules__1NYfr`,
	"item": `ResetPasswordPage_item__zAY+7`,
	"circle": `ResetPasswordPage_circle__xDgDi`,
	"btn": `ResetPasswordPage_btn__gkinW`
};
export default ___CSS_LOADER_EXPORT___;
