import React, { useState, useRef } from "react";
import useScript from "../../../hooks/useScript";
import styles from "./TinkoffPaymentWidget.module.scss";
import cn from "classnames";
import { Input } from "../Input/Input";
import { Button } from "../Button/Button";

import { useAppSelector } from "../../../hooks/redux.hooks";
import generateRandomString from "../../../utils/generateRandomString";

declare global {
  interface Window {
    pay: (form: HTMLFormElement) => void;
  }
}

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const TinkoffPaymentWidget = ({
  active,
  setActive,
  className,
  ...props
}: IProps): JSX.Element => {
  const formRef = useRef<HTMLFormElement>(null);
  const { user } = useAppSelector((state) => state.userSlice);

  const randomString = generateRandomString(10);

  const [formData, setFormData] = useState({
    amount: "",
    order: `${user?.email}-${randomString}`,
    description: "Пополнение баланса",
    name: user?.firstName,
    email: user?.email,
    phone: user?.phoneNumber,
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    const form = formRef.current;

    if (form) {
      form.receipt.value = JSON.stringify({
        Email: user?.email,
        Phone: user?.phoneNumber,
        EmailCompany: "anna@datamost.pro",
        Taxation: "patent",
        Items: [
          {
            Name: "Пополнение баланса",
            Price: form.amount.value + "00",
            Quantity: 1.0,
            Amount: form.amount.value + "00",
            PaymentMethod: "full_prepayment",
            PaymentObject: "service",
            Tax: "none",
          },
        ],
      });
      window.pay(form);
      setActive(false);
    }
  };

  useScript("https://securepay.tinkoff.ru/html/payForm/js/tinkoff_v2.js");

  return (
    <div
      className={cn(styles.modal, {
        [styles.active]: active === true,
      })}
      onClick={() => setActive(false)}
    >
      <div
        className={cn(styles.wrapper, className)}
        onClick={(e) => e.stopPropagation()}
        {...props}
      >
        <div className={styles.title}>
          <h2>Оплата</h2>
        </div>
        <div className={styles.content}>
          <form name="TinkoffPayForm" className={styles.form} ref={formRef}>
            <Input
              type="hidden"
              name="terminalkey"
              value="1680176392711"
              label={""}
            />
            <Input type="hidden" name="frame" value="true" label={""} />
            <Input type="hidden" name="language" value="ru" label={""} />
            <Input
              type="text"
              placeholder="Сумма"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
              required={true}
              label={"Сумма"}
            />
            <input
              type="hidden"
              placeholder="Номер заказа"
              name="order"
              value={formData.order}
              onChange={handleChange}
              required
            />
            <input
              type="hidden"
              placeholder="Почта"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="hidden"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="hidden"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
            <input type="hidden" name="receipt" value="" />
            <Button
              width={"100%"}
              height={"50px"}
              appearance="black"
              type="submit"
              onClick={() => handleSubmit()}
            >
              Оплатить
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default TinkoffPaymentWidget;
