import React from "react";
import styles from "./TransactionListPage.module.scss";
import { Statistic } from "../../index.components";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux.hooks";
import { fetchTransactionsByParams } from "../../../redux/slices/transactionsSlice";
import { format } from "date-fns";
import getStatusLabel from "../../../utils/getStatusLabel";
import cn from "classnames";
import { NavigateTransactionSVG } from "../../../assets/icons/globals.icons";
import { useNavigate } from "react-router-dom";
import {
  ITransaction,
  ITransactionResult,
} from "../../../@types/models/transactions.types";

export const TransactionListPage = (): JSX.Element => {
  const { user } = useAppSelector((state) => state.userSlice);
  const { transactions } = useAppSelector((state) => state.transactionsSlice);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(fetchTransactionsByParams({ email: user?.email }));
  }, [dispatch]);

  const handleClickTransactionId = (transaction: ITransaction) => {
    if (transaction.status === "PENDING") {
      navigate(`../transaction/${transaction.slug}/file`);
    }

    if (transaction.status === "ACCEPTED") {
      navigate(`../transaction/${transaction.slug}/file`);
    }

    if (transaction.status === "CONFIRMATION") {
      navigate(`../transaction/${transaction.slug}/file`);
    }

    if (transaction.status === "COMPARE_DB") {
      navigate(`../transaction/${transaction.slug}/result`);
    }

    if (transaction.status === "SHARE_DB") {
      navigate(`../transaction/${transaction.slug}/result`);
    }

    if (transaction.status === "CONTINUE") {
      navigate(`../transaction/${transaction.slug}/result`);
    }
  };
  console.log(transactions)
  return (
    <div className={styles.transactionListPage}>
      <h1>Транзакции</h1>
      <Statistic />
      <table>
        <tr>
          <th>№ транзакции</th>
          <th>Email партнера</th>
          <th>Статус</th>
          <th>Кол-во строк</th>
          <th>Кол-во совпадений</th>
          <th>Дата сравнения</th>
        </tr>
        {transactions?.map((transaction) => {
          const loadResultJSON: ITransactionResult = JSON.parse(
            transaction.result
          );

          return (
            <tr key={transaction.slug}>
              <td
                className={styles.id}
                onClick={() => handleClickTransactionId(transaction)}
              >
                {transaction.slug.slice(0, 10)}... <NavigateTransactionSVG />
              </td>
              <td>
                {user?.email === transaction.sender?.email
                  ? transaction.notifyRecipientEmail
                  : transaction.sender?.email}
              </td>
              <td className={styles.status}>
                <div
                  className={cn({
                    [styles.pending]: transaction.status === "PENDING",
                    [styles.accepted]: transaction.status === "ACCEPTED",
                    [styles.compare]: transaction.status === "COMPARE_DB",
                    [styles.share]: transaction.status === "SHARE_DB",
                    [styles.continue]: transaction.status === "CONTINUE",
                    [styles.pending]: transaction.status === "CONFIRMATION",
                  })}
                />
                {getStatusLabel(transaction.status)}
              </td>
              {loadResultJSON ? (
                <>
                  {loadResultJSON.sender.partner_email !== user?.email ? (
                    <td>{loadResultJSON.sender.length_of_db_partner}</td>
                  ) : (
                    <td>{loadResultJSON.recipient.length_of_db_partner}</td>
                  )}
                  <td>{loadResultJSON.length_overlaping_data}</td>
                </>
              ) : (
                <>
                  <td></td>
                  <td></td>
                </>
              )}

              <td>{format(new Date(transaction.createdAt), "dd.MM.yyyy")}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};
