import React from "react";
import styles from "./DashboardContainer.module.scss";
import cn from "classnames";

interface IProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const DashboardContainer = ({ className, children, ...props }: IProps): JSX.Element => {
  return (
    <div className={cn(styles.container, className)} {...props}>
      {children}
    </div>
  );
};
