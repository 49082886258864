// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AuthLayout_wrapper__CU8Hw {
  width: 60%;
  margin: 40px auto;
  padding: 50px;
  border: 1px solid rgba(102, 102, 102, 0.3);
  border-radius: 15px;
}
@media (max-width: 700px) {
  .AuthLayout_wrapper__CU8Hw {
    width: 80%;
  }
}
@media (max-width: 500px) {
  .AuthLayout_wrapper__CU8Hw {
    width: 100%;
    padding: 30px;
  }
}
@media (max-width: 360px) {
  .AuthLayout_wrapper__CU8Hw {
    width: 100%;
    padding: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/AuthLayout/AuthLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,iBAAA;EACA,aAAA;EACA,0CAAA;EACA,mBAAA;AACF;AACE;EACE;IACE,UAAA;EACJ;AACF;AAEE;EACE;IACE,WAAA;IACA,aAAA;EAAJ;AACF;AAGE;EACE;IACE,WAAA;IACA,aAAA;EADJ;AACF","sourcesContent":[".wrapper {\n  width: 60%;\n  margin: 40px auto;\n  padding: 50px;\n  border: 1px solid rgba(102, 102, 102, 0.3);\n  border-radius: 15px;\n\n  @media (max-width: 700px) {\n    & {\n      width: 80%;\n    }\n  }\n\n  @media (max-width: 500px) {\n    & {\n      width: 100%;\n      padding: 30px;\n    }\n  }\n\n  @media (max-width: 360px) {\n    & {\n      width: 100%;\n      padding: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `AuthLayout_wrapper__CU8Hw`
};
export default ___CSS_LOADER_EXPORT___;
