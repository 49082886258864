import { combineReducers } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import transactionsSlice from "./slices/transactionsSlice";

const rootReducer = combineReducers({
  userSlice,
  transactionsSlice,
});

export default rootReducer;
