import React from "react";
import styles from "./NavigationPanel.module.scss";
import { Button } from "../../../index.components";
import { ProfileSVG, TransactionSVG } from "../../../../assets/icons/globals.icons";
import { useLocation, useNavigate } from "react-router-dom";
import cn from "classnames";

export const NavigationPanel = (): JSX.Element => {
  const navigate = useNavigate();
  const location = useLocation();

  const isNavigationActive = (route: string): boolean => {
    return location.pathname === route;
  };

  return (
    <div className={styles.panel}>
      <Button
        width="200px"
        height="55px"
        appearance="black"
        addBtn={true}
        onClick={() => navigate("transaction-create")}>
        Новая связь
      </Button>
      <ul className={styles.navigation}>
        <li
          className={cn(styles.item, {
            [styles.active]: isNavigationActive("/dashboard/profile"),
          })}
          onClick={() => navigate("profile")}>
          <ProfileSVG />
          <p>Профиль</p>
        </li>
        <li
          className={cn(styles.item, {
            [styles.active]: isNavigationActive("/dashboard/transactions"),
          })}
          onClick={() => navigate("transactions")}>
          <TransactionSVG />
          <p>Транзакции</p>
        </li>
      </ul>
    </div>
  );
};
