import React from "react";
import styles from "./Error404Page.module.scss";
import { useRouteError } from "react-router-dom";

export const Error404Page = (): JSX.Element => {
  const error = useRouteError();

  return (
    <div className={styles.wrapper}>
      <h1>Уупс!</h1>
      <p>Запрошенная страница не найдена</p>
    </div>
  );
};
