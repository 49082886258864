// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DashboardContainer_container__s1m0w {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 170px 0 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/DashboardLayout/DashboardContainer/DashboardContainer.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,cAAA;EACA,uBAAA;AACF","sourcesContent":[".container {\n  width: 100%;\n  max-width: 1440px;\n  margin: 0 auto;\n  padding: 0 170px 0 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `DashboardContainer_container__s1m0w`
};
export default ___CSS_LOADER_EXPORT___;
