// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Balance_balance__0PjTb {
  display: grid;
  row-gap: 50px;
  padding: 15px;
  background-color: rgb(247, 249, 250);
  border-radius: 15px;
  max-width: 230px;
}
.Balance_balance__0PjTb .Balance_title__Oa-YW {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Balance_balance__0PjTb .Balance_title__Oa-YW p {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: rgb(67, 69, 79);
}
.Balance_balance__0PjTb .Balance_title__Oa-YW svg {
  cursor: pointer;
}
.Balance_balance__0PjTb .Balance_info__J-6NE {
  font-weight: 500;
  font-size: 40px;
  line-height: 113.7%;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Balance/Balance.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,aAAA;EACA,oCAAA;EACA,mBAAA;EACA,gBAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACJ;AACI;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AACN;AAEI;EACE,eAAA;AAAN;AAIE;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;AAFJ","sourcesContent":[".balance {\n  display: grid;\n  row-gap: 50px;\n  padding: 15px;\n  background-color: rgba(247, 249, 250, 1);\n  border-radius: 15px;\n  max-width: 230px;\n\n  .title {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    p {\n      font-weight: 500;\n      font-size: 16px;\n      line-height: 26px;\n      color: rgba(67, 69, 79, 1);\n    }\n\n    svg {\n      cursor: pointer;\n    }\n  }\n\n  .info {\n    font-weight: 500;\n    font-size: 40px;\n    line-height: 113.7%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"balance": `Balance_balance__0PjTb`,
	"title": `Balance_title__Oa-YW`,
	"info": `Balance_info__J-6NE`
};
export default ___CSS_LOADER_EXPORT___;
