import React from "react";
import { LogoSVG } from "../../../../assets/icons/globals.icons";
import styles from "./Header.module.scss";
import { ArrowDownSVG, DefaultAvatarSVG } from "../../../../assets/icons/globals.icons";
import { useAppSelector } from "../../../../hooks/redux.hooks";
import { useActions } from "../../../../hooks/useActions";

export const Header = (): JSX.Element => {
  const { user } = useAppSelector((state) => state.userSlice);
  const { logout } = useActions();

  const [activeLogoutModal, setActiveLogoutModal] = React.useState<boolean>(false);

  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <LogoSVG />
      </div>
      <div className={styles.user}>
        <div className={styles.userAvatar}>
          {user?.avatar ? (
            <img className={styles.avatar} src={user.avatar} alt="avatar" />
          ) : (
            <DefaultAvatarSVG />
          )}
        </div>
        <p>{user?.firstName || "Anonymous"}</p>
        <ArrowDownSVG
          className={styles.arrow}
          onClick={() => setActiveLogoutModal((prev) => !prev)}
        />
        {activeLogoutModal ? (
          <div className={styles.logout} onClick={() => logout()}>
            <p>Выйти</p>
          </div>
        ) : null}
      </div>
    </header>
  );
};
