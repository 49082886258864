export const benefitsInfoBlock = [
  {
    number: 1,
    title: "Оценить потенциал партнерства",
    description: "Вы сможете понять, сколько у вас общих клиентов, а сколько потенциальных. ",
  },
  {
    number: 2,
    title: "Настроить кросс-маркетинг и дальнейшие продажи",
    description: "Вы сможете запустить дополнительные активности на клиентов друг друга.",
  },
  {
    number: 3,
    title: "Обменяться дополнительной информацией о клиентах",
    description:
      "Договоритесь с партнёром о данных, которыми вы будете обмениваться. Сделайте это легко и просто по общим клиентам",
  },
];

export const warningsInfoBlock = [
  {
    number: 1,
    title: "Процесс принятия решения об обмене данными долгий.",
    description: "Из-за NDA и проверок службой безопасности.",
  },
  {
    number: 2,
    title: "Кто-то из партнёров отправляет свои данные первым.",
    description: "И рискует быть обманутым.",
  },
  {
    number: 3,
    title: "Есть риск утечки данных при обмене через мессенджеры или другие каналы.",
    description: "Ведь вы показываете партнёру всю свою базу целиком.",
  },
];
