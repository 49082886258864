import React, {useEffect, useMemo} from "react";
import styles from "./SignUpPage.module.scss";
import {
  Input,
  Button,
  Form,
  CustomInputMask,
  VerifyPage,
} from "../../index.components";
import { IUserSignUpForm } from "../../../@types/models/users.types";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import userService from "../../../services/userService";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce"

const validationSchema = yup.object({
  firstName: yup.string().required("Введите Имя"),
  lastName: yup.string().required("Введите Фамилию"),
  email: yup
    .string()
    .email("Неверный формат почты")
    .required("Введите почту")
    .test(
      "user-exists",
      "Пользователь с такой почтой уже существует",
      async (value) => {
        const data = await userService.userExists(value);
        return !data.exists;
      }
    ),
  phoneNumber: yup
    .string()
    .required("Введите номер телефона")
    .matches(/^\+7 \d{3} \d{3} \d{2} \d{2}$/, "Неверный формат номер телефона"),
  password: yup
    .string()
    .min(8, "Должно быть не менее 8 символов")
    .matches(/^(?=.*[a-z])/, "Должен содержать одну строчную букву")
    .matches(/^(?=.*[A-Z])/, "Должен содержать одну заглавную букву")
    .matches(/^(?=.*\d)/, "Должен содержать одну цифру")
    .matches(
      /^(?=.*[!@#$%^&*])/,
      "Должен содержать один из следующих символов: !@#$%^&*"
    )
    .required("Введите пароль"),
  rePassword: yup
    .string()
    .oneOf([yup.ref("password")], "Пароль не совпадает")
    .required("Повторите пароль"),
});

export const SignUpPage = (): JSX.Element => {
  const initialValues: IUserSignUpForm = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    rePassword: "",
  };

  const navigate = useNavigate();

  const [isRegistered, setIsRegistered] = React.useState(false);
  const [email, setEmail] = React.useState("");

  const formik = useFormik({
    initialValues,
    validateOnMount:true,
    validateOnChange:false,
    onSubmit: async (values) => {
      try {
        const data = await userService.signUp(values);
        toast.success(
          "На вашу почту отправлено подтверждение электронного адреса"
        );
        setEmail(data.email);
        setIsRegistered(true);
      } catch (error) {
        toast.error("Непредвиденная ошибка");
      }
    },
    validationSchema,
  });
  const debouncedValidate = useMemo(
    () => debounce(formik.validateForm, 500),
    [formik.validateForm],
  );

  useEffect(
    () => {
      debouncedValidate(formik.values);

      return () => debouncedValidate.cancel()
    },
    [formik.values.email, debouncedValidate],
  );
  if (!isRegistered) {
    return (
      <>
        <h1 className={styles.signUp}>Регистрация</h1>
        <p className={styles.desc}>
          Для использования нашего сервиса вам необходимо зарегистрироваться.
        </p>
        <Form className={styles.form} onSubmit={formik.handleSubmit}>
          <Input
            id="firstName"
            type="text"
            placeholder="Введите Имя"
            label="Имя"
            errorMessage={
              formik.touched.firstName && formik.errors.firstName
                ? formik.errors.firstName
                : ""
            }
            onChange={formik.handleChange}
          />
          <Input
            id="lastName"
            type="text"
            placeholder="Введите Фамилию"
            label="Фамилия"
            errorMessage={
              formik.touched.lastName && formik.errors.lastName
                ? formik.errors.lastName
                : ""
            }
            onChange={formik.handleChange}
          />
          <Input
            id="email"
            type="email"
            placeholder="Введите почту"
            label="Электронная почта"
            errorMessage={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""
            }
            onChange={formik.handleChange}
          />
          <CustomInputMask
            id="phoneNumber"
            type="text"
            placeholder="Введите номер телефона"
            label="Номер телефона"
            errorMessage={
              formik.touched.phoneNumber && formik.errors.phoneNumber
                ? formik.errors.phoneNumber
                : ""
            }
            onChange={formik.handleChange}
          />
          <Input
            id="password"
            type="password"
            placeholder="Введите пароль"
            label="Пароль"
            errorMessage={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ""
            }
            onChange={formik.handleChange}
          />
          <div className={styles.rules}>
            <div className={styles.item}>
              <div className={styles.circle} />
              <p>Больше 8 символов</p>
            </div>
            <div className={styles.item}>
              <div className={styles.circle} />
              <p>Буквы с нижним и верхним регистром</p>
            </div>
            <div className={styles.item}>
              <div className={styles.circle} />
              <p>Минимум 1 цифра в пароле</p>
            </div>
            <div className={styles.item}>
              <div className={styles.circle} />
              <p>Используйте символы (Например: !@#$)</p>
            </div>
          </div>
          <Input
            id="rePassword"
            type="password"
            placeholder="Подтвердите пароль"
            label="Подтвердите пароль"
            errorMessage={
              formik.touched.rePassword && formik.errors.rePassword
                ? formik.errors.rePassword
                : ""
            }
            onChange={formik.handleChange}
          />
          <Button
            className={styles.btn}
            width="255px"
            height="55px"
            appearance="black"
            disabled={formik.isSubmitting}
            isSubmitting={formik.isSubmitting}
          >
            Зарегистрироваться
          </Button>
        </Form>
        <p className={styles.confirmation}>
          Создавая аккаунт, вы соглашаетесь с Условиями использования и
          Политикой конфиденциальности.
        </p>
      </>
    );
  }

  if (isRegistered) return <VerifyPage email={email} />;

  return <></>;
};
