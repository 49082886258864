import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ITransaction, ITransactionSearchParams } from "../../@types/models/transactions.types";
import transactionsService from "../../services/transactionsService";

export const fetchTransactionsByParams = createAsyncThunk(
  "transactions/fetchTransactionsByParams",
  async (params: ITransactionSearchParams, { rejectWithValue }) => {
    try {
      const data = await transactionsService.fetchTransactionsByParams(params);
      return data;
    } catch (error) {
      const e = error as AxiosError;
      return rejectWithValue(e.response?.data);
    }
  },
);

interface IInitialState {
  transactions: ITransaction[] | null;
  status: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
  isLoadingFile: boolean;
}

const initialState: IInitialState = {
  transactions: null,
  status: "idle",
  error: null,
  isLoadingFile: false,
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    setIsLoadingFile(state,action) {
      state.isLoadingFile = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactionsByParams.pending, (state: IInitialState) => {
        state.status = "pending";
      })
      .addCase(
        fetchTransactionsByParams.fulfilled,
        (state: IInitialState, action: PayloadAction<ITransaction[]>) => {
          state.transactions = action.payload;
          state.status = "succeeded";
        },
      )
      .addCase(fetchTransactionsByParams.rejected, (state: IInitialState, action) => {
        state.error = action.payload as string;
        state.status = "failed";
      });
  },
});

export const transactionsActions = transactionsSlice.actions;

export default transactionsSlice.reducer;
