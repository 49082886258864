// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loading_loading__lMdTY {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.Loading_loading__lMdTY .Loading_logo__VMW9X {
  width: 100px;
  height: 100px;
}`, "",{"version":3,"sources":["webpack://./src/components/common/Loading/Loading.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,uBAAA;AACF;AACE;EACE,YAAA;EACA,aAAA;AACJ","sourcesContent":[".loading {\n  width: 100vw;\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n\n  .logo {\n    width: 100px;\n    height: 100px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": `Loading_loading__lMdTY`,
	"logo": `Loading_logo__VMW9X`
};
export default ___CSS_LOADER_EXPORT___;
