import React from "react";
import styles from "./DashboardLayout.module.scss";
import { Header } from "./Header/Header";
import { NavigationPanel } from "./NavigationPanel/NavigationPanel";
import { Outlet } from "react-router-dom";
import { DashboardContainer } from "./DashboardContainer/DashboardContainer";
import {LoadingPopup} from "../../common/LoadingPopup/LoadingPopup";
import {useAppSelector} from "../../../hooks/redux.hooks";

export const DashboardLayout = (): JSX.Element => {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  const { isLoadingFile } = useAppSelector(state => state.transactionsSlice)

  React.useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const showScreenSizePermission = screenWidth <= 1100;

  return (
    <>
      {isLoadingFile && <LoadingPopup/>}
      <DashboardContainer>
        <div className={styles.wrapper}>
          <Header/>
          {showScreenSizePermission ? (
            <div className={styles.screenSizePermission}>
              Личный кабинет недоступен в мобильной версии
            </div>
          ) : (
            <div className={styles.content}>
              <NavigationPanel/>
              <Outlet/>
            </div>
          )}
        </div>
      </DashboardContainer>
    </>
  );
};
