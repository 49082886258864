import React from "react";
import styles from "./LandingPage.module.scss";
import { Button, Container } from "../../index.components";
import { InfoBlock } from "./InfoBlock/InfoBlock";
import { benefitsInfoBlock, warningsInfoBlock } from "./LandingPage.constants";
import {
  ShieldSVG,
  B2BSVG,
  DashboardSVG,
  FileUploadSVG,
  DashboardFrameSVG,
  RegisterSVG,
  RegisterFrameSVG,
  BalanceSVG,
  CardsSVG,
  BalanceFrameSVG,
  ConnectSVG,
  ConnectBtnSVG,
  ConnectFrameSVG,
  FileUploadAreaSVG,
  UploadingFileSVG,
  FileUploadFrameSVG,
  TransactionSVG,
  TransactionConfirmSVG,
  TransactionFrameSVG,
  SaveResultSVG,
  SaveResultFrameSVG,
} from "../../../assets/icons/landing.icons";
import { LogoSVG } from "../../../assets/icons/globals.icons";
import scrollToSection from "../../../utils/scrollToSection";
import { useNavigate } from "react-router-dom";

export const LandingPage = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <>
      <Container>
        <div className={styles.intro} id="intro">
          <div className={styles.info}>
            <h1>Сервис для сравнения данных по клиентам</h1>
            <p>
              Узнайте, сколько у вас и вашего партнера общих клиентов и
              обогатите базы друг друга быстро и безопасно.
            </p>
            <div className={styles.buttons}>
              <Button
                width="220px"
                height="55px"
                appearance="black"
                className={styles.btn}
                onClick={() => navigate("auth/sign-in")}
              >
                Попробовать
              </Button>
              <Button
                width="220px"
                height="55px"
                appearance="white"
                className={styles.btn}
                onClick={() => scrollToSection("howItWorks")}
              >
                Подробнее
              </Button>
            </div>
          </div>
          <div className={styles.icons}>
            <DashboardSVG className={styles.dashboardSvg} />
            <FileUploadSVG className={styles.fileUploadSvg} />
            <DashboardFrameSVG className={styles.dashboardFrameSvg} />
          </div>
        </div>
        <div className={styles.fits}>
          <div className={styles.info}>
            <p className={styles.title}>Кому подойдет MOST?</p>
            <p className={styles.desc}>
              Сервис подходит компаниям, которые работают в b2b и хотят сравнить
              данные о клиентах со своими партнёрами. 
            </p>
          </div>
          <B2BSVG />
        </div>
        <div className={styles.benefits}>
          <h2>Совместная работа с данными позволит</h2>
          <div className={styles.items}>
            {benefitsInfoBlock.map((info, index) => (
              <InfoBlock
                key={index}
                number={info.number}
                title={info.title}
                description={info.description}
                appearance={"benefit"}
              />
            ))}
          </div>
        </div>
      </Container>
      <div className={styles.security}>
        <Container className={styles.wrapper}>
          <div className={styles.info}>
            <h2>Сверяйте базы безопасно</h2>
            <ul>
              <li>
                Мы ничего не знаем о вас и вашей компании, поэтому данные для
                нас бесполезны
              </li>
              <li>
                Вы можете удалить результаты всех сверок, и мы правда их удалим,
                без возможности восстановления
              </li>
            </ul>
          </div>
          <ShieldSVG className={styles.svg} />
        </Container>
      </div>

      <Container>
        <div className={styles.warnings}>
          <h2>
            Сейчас нет быстрого, удобного и безопасного способа сверить данные о
            клиентах
          </h2>
          <div className={styles.items}>
            {warningsInfoBlock.map((info, index) => (
              <InfoBlock
                key={index}
                number={info.number}
                title={info.title}
                description={info.description}
                appearance={"warn"}
              />
            ))}
          </div>
        </div>
        <div className={styles.features}>
          <h2>
            <LogoSVG /> позволит вам и партнёру обогатить базы клиентов быстро и
            безопасно
          </h2>

          <div className={styles.infoBlock}>
            <div className={styles.info}>
              <ul>
                <li>Сравнивайте базы не показав партнёру лишнего</li>
                <li>
                  Обменивайтесь дополнительной информацией только по обоюдному
                  согласию и только по общим клиентам
                </li>
                <li>
                  Мы не знаем, из какой вы компании, поэтому для нас все данные
                  обезличены.
                </li>
              </ul>
            </div>
            <Button
              width="220px"
              height="55px"
              appearance="black"
              className={styles.btn}
              onClick={() => navigate("auth/sign-in")}
            >
              Попробовать
            </Button>
          </div>
        </div>
      </Container>
      <Container>
        <div className={styles.howItWorks} id="howItWorks">
          <div className={styles.title}>
            <LogoSVG />
            <h2>Как это работает?</h2>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.info}>
              <div className={styles.number}>1</div>
              <h3>
                Зарегистрируйтесь в личном кабинете <LogoSVG />
              </h3>
            </div>
            <div className={styles.icons}>
              <RegisterSVG className={styles.registerSvg} />
              <RegisterFrameSVG className={styles.registerFrameSvg} />
            </div>
          </div>
        </div>
      </Container>
      <div className={styles.connect}>
        <Container className={styles.wrapper}>
          <div className={styles.icons}>
            <ConnectSVG className={styles.connectSvg} />
            <ConnectBtnSVG className={styles.connectBtnSvg} />
            <ConnectFrameSVG className={styles.connectFrameSvg} />
          </div>
          <div className={styles.info}>
            <div className={styles.number}>2</div>
            <h3>Пригласите партнера чтобы сравнить данные</h3>
            <p>
              Введите электронную почту партнёра и отправьте ему приглашение.
            </p>
            <p>
              Договоритесь о том, будете ли обмениваться данными по общим
              клиентам.
            </p>
          </div>
        </Container>
      </div>

      <div className={styles.uploadTables}>
        <Container className={styles.wrapper}>
          <div className={styles.info}>
            <div className={styles.number}>3</div>
            <h3>Загружаете файл таблицы</h3>
            <p className={styles.description}>
              Файл должен быть в форматах xls/xlsx/csv с двумя именованными
              столбцами: ИНН, доп.информация.
            </p>
            <p className={styles.warning}>
              *Мы не несем ответственности за то, какие данные вы передаёте в
              MOST, удостоверьтесь, что передавая эти данные, вы не нарушаете
              закон.
            </p>
          </div>
          <div className={styles.icons}>
            <FileUploadAreaSVG className={styles.fileUploadAreaSvg} />
            <UploadingFileSVG className={styles.uploadingFileSvg} />
            <FileUploadFrameSVG className={styles.fileUploadFrameSvg} />
          </div>
        </Container>
      </div>
      <div className={styles.result}>
        <Container className={styles.wrapper}>
          <div className={styles.icons}>
            <TransactionSVG className={styles.transactionSvg} />
            <TransactionConfirmSVG className={styles.confirmSvg} />
            <TransactionFrameSVG className={styles.transactionFrameSvg} />
          </div>
          <div className={styles.info}>
            <div className={styles.number}>4</div>
            <h3>Получаете результат</h3>
            <p>
              После получения результата можете обогатить базы друг друга
              дополнительной информацией.
            </p>
          </div>
        </Container>
      </div>
      <div className={styles.saveResult}>
        <Container className={styles.wrapper}>
          <div className={styles.info}>
            <div className={styles.number}>5</div>
            <h3>Сохраните результат </h3>
            <p>
              Результат сравнения можно отправить себе на почту, указанную в
              профиле.
            </p>
          </div>
          <div className={styles.icons}>
            <SaveResultSVG className={styles.saveResultSvg} />
            <SaveResultFrameSVG className={styles.saveResultFrameSvg} />
          </div>
        </Container>
      </div>
      <Container>
        <div className={styles.rates} id="rates">
          <h2>Тарифы</h2>
          <p className={styles.desc}>
            Тариф за сравнение данных списывается с каждого участника
            транзакции.
          </p>
          <p className={styles.desc}>
            Количество строк определяется по файлу с наибольшим числом строк.
          </p>
          <p className={styles.desc}>
            Если один участник загрузил 880 строк, а второй 3000, то списание
            произойдет по тарифу Medium.
          </p>
          <div className={styles.prices}>
            <div className={styles.item}>
              <h3>Starter</h3>
              <p className={styles.rows}>Каждое сравнение до 1000 строк</p>
              <p className={styles.price}>800 ₽</p>
              <Button
                className={styles.btn}
                width="220px"
                height="55px"
                appearance="white"
                onClick={() => navigate("auth/sign-in")}
              >
                Попробовать
              </Button>
            </div>
            <div className={styles.item}>
              <h3>Medium</h3>
              <p className={styles.rows}>Каждое сравнение до 10 000 строк</p>
              <p className={styles.price}>2900 ₽</p>
              <Button
                className={styles.btn}
                width="220px"
                height="55px"
                appearance="white"
                onClick={() => navigate("auth/sign-in")}
              >
                Попробовать
              </Button>
            </div>
            <div className={styles.item}>
              <h3>Special</h3>
              <p className={styles.rows}>Каждое сравнение свыше 10 000 строк</p>
              <p className={styles.price}>Индивидуально</p>
              <Button
                className={styles.btn}
                width="220px"
                height="55px"
                appearance="black"
                onClick={() => navigate("auth/sign-in")}
              >
                Попробовать
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};
