// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_header__\\+4hPg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}
.Header_header__\\+4hPg .Header_navMobile__IW44e {
  display: none;
}
.Header_header__\\+4hPg .Header_navMobile__IW44e path {
  fill: black;
}
.Header_header__\\+4hPg .Header_navigation__UUKHd {
  display: flex;
  column-gap: 30px;
}
.Header_header__\\+4hPg .Header_navigation__UUKHd .Header_link__KYwAi {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
.Header_header__\\+4hPg .Header_logo__a3V0Y {
  cursor: pointer;
}
.Header_header__\\+4hPg .Header_auth__ZMquB {
  display: flex;
  align-items: center;
  column-gap: 40px;
}
.Header_header__\\+4hPg .Header_auth__ZMquB .Header_logIn__nCW9o {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
@media (max-width: 767px) {
  .Header_header__\\+4hPg {
    justify-content: center;
    position: relative;
  }
  .Header_header__\\+4hPg .Header_navMobile__IW44e {
    display: block;
    position: absolute;
    left: 0;
  }
  .Header_header__\\+4hPg .Header_navigation__UUKHd,
  .Header_header__\\+4hPg .Header_auth__ZMquB {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/AuthLayout/Header/Header.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;AACF;AACE;EACE,aAAA;AACJ;AACI;EACE,WAAA;AACN;AAGE;EACE,aAAA;EACA,gBAAA;AADJ;AAGI;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AADN;AAKE;EACE,eAAA;AAHJ;AAME;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AAJJ;AAMI;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAJN;AAQE;EACE;IACE,uBAAA;IACA,kBAAA;EANJ;EASE;IACE,cAAA;IACA,kBAAA;IACA,OAAA;EAPJ;EAUE;;IAEE,aAAA;EARJ;AACF","sourcesContent":[".header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 15px 0;\n\n  .navMobile {\n    display: none;\n\n    path {\n      fill: black;\n    }\n  }\n\n  .navigation {\n    display: flex;\n    column-gap: 30px;\n\n    .link {\n      font-weight: 700;\n      font-size: 14px;\n      line-height: 20px;\n    }\n  }\n\n  .logo {\n    cursor: pointer;\n  }\n\n  .auth {\n    display: flex;\n    align-items: center;\n    column-gap: 40px;\n\n    .logIn {\n      font-weight: 700;\n      font-size: 14px;\n      line-height: 20px;\n    }\n  }\n\n  @media (max-width: 767px) {\n    & {\n      justify-content: center;\n      position: relative;\n    }\n\n    .navMobile {\n      display: block;\n      position: absolute;\n      left: 0;\n    }\n\n    .navigation,\n    .auth {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `Header_header__+4hPg`,
	"navMobile": `Header_navMobile__IW44e`,
	"navigation": `Header_navigation__UUKHd`,
	"link": `Header_link__KYwAi`,
	"logo": `Header_logo__a3V0Y`,
	"auth": `Header_auth__ZMquB`,
	"logIn": `Header_logIn__nCW9o`
};
export default ___CSS_LOADER_EXPORT___;
