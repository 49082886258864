import React from "react";
import styles from "./CustomInputMask.module.scss";
import { ErrorMessage } from "./ErrorMessage/ErrorMessage";
import InputMask from "react-input-mask";

interface IProps {
  id: string;
  type: string;
  placeholder: string;
  label: string;
  errorMessage?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<any>) => void;
}

export const CustomInputMask = ({
  id,
  type,
  placeholder,
  label,
  errorMessage,
  onChange,
  value,
}: IProps): JSX.Element => {
  return (
    <div className={styles.wrapper}>
      <label htmlFor="" className={styles.label}>
        {label}
        {errorMessage ? <ErrorMessage message={errorMessage} /> : null}
      </label>
      <InputMask
        mask="+7 999 999 99 99"
        id={id}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        className={styles.input}
        value={value}
        autoComplete="one-time-code"
        inputMode="numeric"
      />
    </div>
  );
};
