import React from "react";
import styles from "./TransactionConfirmationPage.module.scss";
import { Input, Button, Balance } from "../../index.components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ITransaction } from "../../../@types/models/transactions.types";
import transactionsService from "../../../services/transactionsService";
import { useAppSelector } from "../../../hooks/redux.hooks";
import { AlertSVG } from "../../../assets/icons/globals.icons";

export const TransactionConfirmationPage = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const { user } = useAppSelector((state) => state.userSlice);
  const [transaction, setTransaction] = React.useState<ITransaction | null>(
    null
  );

  React.useEffect(() => {
    const fetchTransaction = async () => {
      const data = await transactionsService.fetchTransactionBySlug(slug!);
      setTransaction(data);
    };
    fetchTransaction();
  }, [slug]);

  const handleConfirmation = async () => {
    try {
      const updatedTransaction =
        await transactionsService.handleConfirmationTransaction(
          slug!,
          "ACCEPTED",
          user?.id
        );

      if (updatedTransaction.status === "ACCEPTED") {
        toast.success("Вы успешно приняли приглашение");
        navigate(`../transaction/${slug}/file`);
      }
    } catch (error) {}
  };

  if (user?.email === transaction?.sender.email) {
    navigate(`../transaction/${slug}/file`);
  }

  if (transaction?.status === "ACCEPTED") {
    navigate(`../transaction/${slug}/file`);
  }

  if (user?.email === transaction?.notifyRecipientEmail) {
    return (
      <div className={styles.transactionConfirmationPage}>
        <div className={styles.alertBlock}>
          <AlertSVG />
          <div className={styles.alert}>
            <p className={styles.title}>
              Пользователь {transaction?.sender.email} пригласил вас сравнить
              данные
            </p>
          </div>
        </div>
        <h1>Новая связь</h1>
        <Balance />
        <div className={styles.confirmation}>
          <Input
            type="email"
            placeholder="Введите почту вашего партнера"
            label="Электронная почта вашего партнера"
            disabled={true}
            defaultValue={transaction?.sender.email}
          />
          <Button
            className={styles.btn}
            appearance="black"
            width="290px"
            height="55px"
            type="button"
            onClick={handleConfirmation}
          >
            Принять приглашение
          </Button>
        </div>
      </div>
    );
  }

  return <></>;
};
