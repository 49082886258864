import React from "react";
import styles from "./Statistic.module.scss";
import { StatisticItem } from "./StatisticItem/StatisticItem";
import { Balance } from "../Balance/Balance";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux.hooks";
import { fetchTransactionsByParams } from "../../../redux/slices/transactionsSlice";

export const Statistic = () => {
  const { user } = useAppSelector((state) => state.userSlice);
  const { transactions } = useAppSelector((state) => state.transactionsSlice);

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(fetchTransactionsByParams({ email: user?.email }));
  }, [dispatch]);

  const completedTransactions = transactions?.filter(
    (transaction) => transaction.status === "COMPARE_DB" || transaction.status === "SHARE_DB" || transaction.status === "CONTINUE",
  );

  const inProcessTransactions = transactions?.filter(
    (transaction) => transaction.status === "PENDING" || transaction.status === "ACCEPTED",
  );

  return (
    <div className={styles.statistic}>
      <Balance />
      <StatisticItem
        title="Кол-во транзакций"
        info={transactions?.length || 0}
        appearance={"blue"}
      />
      <StatisticItem
        title="Закончены"
        info={completedTransactions?.length || 0}
        appearance={"primary"}
      />
      <StatisticItem
        title="В процессе"
        info={inProcessTransactions?.length || 0}
        appearance={"orange"}
      />
    </div>
  );
};
