import React from "react";
import styles from "./NavMobile.module.scss";
import cn from "classnames";
import { useNavigate } from "react-router-dom";

interface IProps {
  isActive: boolean;
}

export const NavMobile = ({ isActive }: IProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div
      className={cn(styles.navMobile, {
        [styles.active]: isActive,
      })}>
      <ul>
        <li ><a href="https://datamost.su/">О нас</a></li>
        <li ><a href="https://datamost.su/#fact">Как это работает</a></li>
        <li ><a href="https://datamost.su/#tarif">Тарифы</a></li>
        <li onClick={() => navigate("auth/sign-in")}>Войти</li>
        <li onClick={() => navigate("auth/sign-up")}>Регистрация</li>
      </ul>
    </div>
  );
};
