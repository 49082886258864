import React from "react";
import styles from "./Container.module.scss";
import cn from "classnames";

interface IProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {}

export const Container = ({ children, className, ...props }: IProps): JSX.Element => {
  return (
    <div className={cn(styles.container, className)} {...props}>
      {children}
    </div>
  );
};
