import React from "react";
import styles from "./Header.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { Container, Button } from "../../../index.components";
import { LogoSVG, NavSVG, CloseSVG } from "../../../../assets/icons/globals.icons";

export const Header = (): JSX.Element => {
  const [activeNavMob, setActiveNavMob] = React.useState<boolean>(false);

  const handleClickNavMob = () => {
    setActiveNavMob(true);
  };

  const navigate = useNavigate();

  return (
    <Container>
      <header className={styles.header}>
        {activeNavMob ? (
          <CloseSVG className={styles.navMobile} />
        ) : (
          <NavSVG className={styles.navMobile} onClick={handleClickNavMob} />
        )}
        <div className={styles.navigation}>
          <a href="https://datamost.su/" className={styles.link}>
            О нас
          </a>
          <a href="https://datamost.su/#fact" className={styles.link}>
            Как это работает?
          </a>
          <a
            href="https://datamost.su/#tarif"
            className={styles.link}>
            Тарифы
          </a>
        </div>
        <LogoSVG className={styles.logo} onClick={() => navigate("..")} />
        <div className={styles.auth}>
          <Link to={"sign-in"} className={styles.logIn}>
            Войти
          </Link>
          <Button
            onClick={() => navigate("sign-up")}
            width="170px"
            height="40px"
            appearance="black">
            Регистрация
          </Button>
        </div>
      </header>
    </Container>
  );
};
