import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { clearCookies } from "../../utils/jwt";
import { toast } from "react-toastify";
import { IUser } from "../../@types/models/users.types";
import userService from "../../services/userService";

export const fetchUser = createAsyncThunk("user/fetchUser", async (_, { rejectWithValue }) => {
  try {
    const data = await userService.fetchUser();
    return data;
  } catch (error) {
    const e = error as AxiosError;
    return rejectWithValue(e.response?.data);
  }
});

interface IInitialState {
  user: IUser | null;
  isAuthenticated: boolean;
  status: "idle" | "pending" | "succeeded" | "failed";
  error: string | null;
}

const initialState: IInitialState = {
  user: null,
  isAuthenticated: false,
  status: "idle",
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout(state) {
      clearCookies();
      state.isAuthenticated = false;
      state.user = null;
      toast.success("Вы вышли из системы");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state: IInitialState) => {
        state.status = "pending";
      })
      .addCase(fetchUser.fulfilled, (state: IInitialState, action: PayloadAction<IUser>) => {
        state.user = action.payload;
        state.isAuthenticated = true;
        state.status = "succeeded";
      })
      .addCase(fetchUser.rejected, (state: IInitialState, action) => {
        state.error = action.payload as string;
        state.status = "failed";
      });
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
