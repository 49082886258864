// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VerifyPage_verifyPage__MxClu {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.VerifyPage_verifyPage__MxClu h1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 113.7%;
}
.VerifyPage_verifyPage__MxClu p {
  align-self: center;
  max-width: 500px;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #43454f;
  margin-top: 10px;
}
.VerifyPage_verifyPage__MxClu p span {
  color: #0029ff;
  opacity: 0.7;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/SignUpPage/VerifyPage/VerifyPage.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,kBAAA;AACF;AACE;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;AACJ;AAEE;EACE,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;AAAJ;AAEI;EACE,cAAA;EACA,YAAA;AAAN","sourcesContent":[".verifyPage {\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n\n  h1 {\n    font-weight: 500;\n    font-size: 40px;\n    line-height: 113.7%;\n  }\n\n  p {\n    align-self: center;\n    max-width: 500px;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 26px;\n    color: #43454f;\n    margin-top: 10px;\n\n    span {\n      color: #0029ff;\n      opacity: 0.7;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"verifyPage": `VerifyPage_verifyPage__MxClu`
};
export default ___CSS_LOADER_EXPORT___;
