// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LandingLayout_wrapper__ZThob {
  display: grid;
  min-height: 100vh;
  grid-template: auto 1fr auto/1fr;
  grid-template-areas: "header" "content" "footer";
}
.LandingLayout_wrapper__ZThob .LandingLayout_header__BJE6p {
  grid-area: header;
}
.LandingLayout_wrapper__ZThob .LandingLayout_content__JNrHA {
  grid-area: content;
}
.LandingLayout_wrapper__ZThob .LandingLayout_footer__j9r3I {
  grid-area: footer;
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/LandingLayout/LandingLayout.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,iBAAA;EACA,gCAAA;EACA,gDACE;AAAJ;AAIE;EACE,iBAAA;AAFJ;AAKE;EACE,kBAAA;AAHJ;AAME;EACE,iBAAA;AAJJ","sourcesContent":[".wrapper {\n  display: grid;\n  min-height: 100vh;\n  grid-template: auto 1fr auto / 1fr;\n  grid-template-areas:\n    \"header\"\n    \"content\"\n    \"footer\";\n\n  .header {\n    grid-area: header;\n  }\n\n  .content {\n    grid-area: content;\n  }\n\n  .footer {\n    grid-area: footer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `LandingLayout_wrapper__ZThob`,
	"header": `LandingLayout_header__BJE6p`,
	"content": `LandingLayout_content__JNrHA`,
	"footer": `LandingLayout_footer__j9r3I`
};
export default ___CSS_LOADER_EXPORT___;
