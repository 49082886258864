import React, {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux.hooks";
import styles from "./Balance.module.scss";
import removeDecimal from "../../../utils/removeDecimal";
import { AddSquareSVG } from "../../../assets/icons/globals.icons";
import TinkoffPaymentWidget from "../TinkoffPaymentWidget/TinkoffPaymentWidget";
import {fetchUser} from "../../../redux/slices/userSlice";

export const Balance = (): JSX.Element => {
  const { user } = useAppSelector((state) => state.userSlice);
  const [isPay, setIsPay] = React.useState<boolean>(false);
  const dispatch = useAppDispatch()

  const handleClickPay = () => {
    setIsPay(!isPay);
  };

  useEffect(() => {
    const handleMessage = (event:any) => {

      if(event.data == '[AppState] SET_READY_APP') dispatch(fetchUser())
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);
  return (
    <div className={styles.balance}>
      <div className={styles.title}>
        <p>Баланс</p>
        <AddSquareSVG onClick={handleClickPay} />
        {isPay && <TinkoffPaymentWidget active={isPay} setActive={setIsPay} />}
      </div>
      <p className={styles.info}>{removeDecimal(user?.balance)}</p>
    </div>
  );
};
