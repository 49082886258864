// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InfoBlock_infoBlock__vcUvX {
  padding: 30px;
  border-radius: 15px;
}
.InfoBlock_infoBlock__vcUvX .InfoBlock_number__qnXqi {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}
.InfoBlock_infoBlock__vcUvX .InfoBlock_info__PjhIj {
  margin-top: 30px;
}
.InfoBlock_infoBlock__vcUvX .InfoBlock_info__PjhIj h3 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
}
.InfoBlock_infoBlock__vcUvX .InfoBlock_info__PjhIj p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin-top: 10px;
  color: #43454f;
}

.InfoBlock_benefit__CSa3X {
  background: linear-gradient(0deg, rgba(248, 248, 252, 0.6), rgba(248, 248, 252, 0.6)), #ffffff;
}
.InfoBlock_benefit__CSa3X .InfoBlock_number__qnXqi {
  background: linear-gradient(0deg, #54e9b8, #54e9b8), #ffffff;
}

.InfoBlock_warn__tRXuk {
  background: linear-gradient(0deg, rgba(255, 234, 234, 0.6), rgba(255, 234, 234, 0.6)), #ffffff;
}
.InfoBlock_warn__tRXuk .InfoBlock_number__qnXqi {
  background: rgba(238, 29, 82, 0.4);
}`, "",{"version":3,"sources":["webpack://./src/components/pages/LandingPage/InfoBlock/InfoBlock.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;AACE;EACE,oBAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACJ;AAEE;EACE,gBAAA;AAAJ;AAEI;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AAAN;AAGI;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AADN;;AAMA;EACE,8FAAA;AAHF;AAKE;EACE,4DAAA;AAHJ;;AAOA;EACE,8FAAA;AAJF;AAME;EACE,kCAAA;AAJJ","sourcesContent":[".infoBlock {\n  padding: 30px;\n  border-radius: 15px;\n\n  .number {\n    display: inline-flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 50%;\n    width: 40px;\n    height: 40px;\n  }\n\n  .info {\n    margin-top: 30px;\n\n    h3 {\n      font-weight: 500;\n      font-size: 20px;\n      line-height: 24px;\n    }\n\n    p {\n      font-weight: 400;\n      font-size: 16px;\n      line-height: 26px;\n      margin-top: 10px;\n      color: #43454f;\n    }\n  }\n}\n\n.benefit {\n  background: linear-gradient(0deg, rgba(248, 248, 252, 0.6), rgba(248, 248, 252, 0.6)), #ffffff;\n\n  .number {\n    background: linear-gradient(0deg, #54e9b8, #54e9b8), #ffffff;\n  }\n}\n\n.warn {\n  background: linear-gradient(0deg, rgba(255, 234, 234, 0.6), rgba(255, 234, 234, 0.6)), #ffffff;\n\n  .number {\n    background: rgba(238, 29, 82, 0.4);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoBlock": `InfoBlock_infoBlock__vcUvX`,
	"number": `InfoBlock_number__qnXqi`,
	"info": `InfoBlock_info__PjhIj`,
	"benefit": `InfoBlock_benefit__CSa3X`,
	"warn": `InfoBlock_warn__tRXuk`
};
export default ___CSS_LOADER_EXPORT___;
