// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransactionConfirmationPage_transactionConfirmationPage__Pu3z8 .TransactionConfirmationPage_alertBlock__u3jez {
  display: flex;
  align-items: center;
  column-gap: 15px;
  background: rgba(255, 160, 72, 0.12);
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 35px;
}
.TransactionConfirmationPage_transactionConfirmationPage__Pu3z8 .TransactionConfirmationPage_alertBlock__u3jez .TransactionConfirmationPage_alert__wZ0xK .TransactionConfirmationPage_title__YDd62 {
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}
.TransactionConfirmationPage_transactionConfirmationPage__Pu3z8 h1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 113.7%;
  margin-bottom: 35px;
}
.TransactionConfirmationPage_transactionConfirmationPage__Pu3z8 .TransactionConfirmationPage_confirmation__ey0mS {
  margin-top: 35px;
  max-width: 550px;
}
.TransactionConfirmationPage_transactionConfirmationPage__Pu3z8 .TransactionConfirmationPage_confirmation__ey0mS .TransactionConfirmationPage_btn__FKRPj {
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/TransactionConfirmationPage/TransactionConfirmationPage.module.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,oCAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;AAAJ;AAGM;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;AADR;AAKE;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,mBAAA;AAHJ;AAME;EACE,gBAAA;EACA,gBAAA;AAJJ;AAMI;EACE,gBAAA;AAJN","sourcesContent":[".transactionConfirmationPage {\n  .alertBlock {\n    display: flex;\n    align-items: center;\n    column-gap: 15px;\n    background: rgba(255, 160, 72, 0.12);\n    border-radius: 10px;\n    padding: 15px;\n    margin-bottom: 35px;\n\n    .alert {\n      .title {\n        font-weight: 500;\n        font-size: 24px;\n        line-height: 29px;\n      }\n    }\n  }\n  h1 {\n    font-weight: 500;\n    font-size: 40px;\n    line-height: 113.7%;\n    margin-bottom: 35px;\n  }\n\n  .confirmation {\n    margin-top: 35px;\n    max-width: 550px;\n\n    .btn {\n      margin-top: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transactionConfirmationPage": `TransactionConfirmationPage_transactionConfirmationPage__Pu3z8`,
	"alertBlock": `TransactionConfirmationPage_alertBlock__u3jez`,
	"alert": `TransactionConfirmationPage_alert__wZ0xK`,
	"title": `TransactionConfirmationPage_title__YDd62`,
	"confirmation": `TransactionConfirmationPage_confirmation__ey0mS`,
	"btn": `TransactionConfirmationPage_btn__FKRPj`
};
export default ___CSS_LOADER_EXPORT___;
