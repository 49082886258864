import React from "react";
import styles from "./ResetPasswordPage.module.scss";
import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import $api from "../../../services/axios";
import { Button, Form, Input } from "../../index.components";
import { IUserResetPasswordForm } from "../../../@types/models/users.types";
import { useNavigate, useSearchParams } from "react-router-dom";

const validationSchema = yup.object({
  newPassword: yup
    .string()
    .min(6, "Должно быть не менее 6 символов")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])/, "Должен содержать один верхний регистр")
    .required("Введите пароль"),
  reNewPassword: yup
    .string()
    .oneOf([yup.ref("newPassword")], "Пароль не совпадает")
    .required("Повторите пароль"),
});

export const ResetPasswordPage = (): JSX.Element => {
  const initialValues: IUserResetPasswordForm = {
    uid: "",
    token: "",
    newPassword: "",
    reNewPassword: "",
  };

  const [confirmationParams] = useSearchParams();

  const navigate = useNavigate();

  const uid = confirmationParams.get("uid");
  const token = confirmationParams.get("token");

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      try {
        await $api.post("auth/users/reset_password_confirm/", {
          ...values,
          uid,
          token,
        });
        navigate("/auth/sign-in");
        toast.success("Пароль изменён");
      } catch (error) {
        toast.error("Непредвиденная ошибка");
      }
    },
    validationSchema,
  });

  return (
    <>
      <h1 className={styles.resetPassword}>Придумайте пароль</h1>
      <Form className={styles.form} onSubmit={formik.handleSubmit}>
        <Input
          id="newPassword"
          type="password"
          placeholder="Введите новый пароль"
          label="Новый пароль"
          errorMessage={
            formik.touched.newPassword && formik.errors.newPassword ? formik.errors.newPassword : ""
          }
          onChange={formik.handleChange}
        />
        <div className={styles.rules}>
          <div className={styles.item}>
            <div className={styles.circle} />
            <p>Больше 8 символов</p>
          </div>
          <div className={styles.item}>
            <div className={styles.circle} />
            <p>Буквы с нижним и верхним регистром</p>
          </div>
          <div className={styles.item}>
            <div className={styles.circle} />
            <p>Минимум 1 цифра в пароле</p>
          </div>
          <div className={styles.item}>
            <div className={styles.circle} />
            <p>Используйте символы (Например: !@#$)</p>
          </div>
        </div>
        <Input
          id="reNewPassword"
          type="password"
          placeholder="Подтвердите пароль"
          label="Подтвердите пароль"
          errorMessage={
            formik.touched.reNewPassword && formik.errors.reNewPassword
              ? formik.errors.reNewPassword
              : ""
          }
          onChange={formik.handleChange}
        />
        <Button
          className={styles.btn}
          width="185px"
          height="55px"
          appearance="black"
          isSubmitting={formik.isSubmitting}>
          Продолжить
        </Button>
      </Form>
    </>
  );
};
