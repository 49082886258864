import React from "react";
import styles from "./TransactionDeleteModal.module.scss";
import cn from "classnames";
import { Button } from "../../../index.components";
import transactionsService from "../../../../services/transactionsService";
import { ITransaction } from "../../../../@types/models/transactions.types";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  active: boolean;
  setActive: React.Dispatch<React.SetStateAction<boolean>>;
  transaction: ITransaction;
}

export const TransactionDeleteModal = ({
  active,
  setActive,
  transaction,
  className,
  ...props
}: IProps) => {
  const navigate = useNavigate();
  const handleClickCancel = () => {
    setActive(false);
  };

  const handleClickDelete = async () => {
    try {
      await transactionsService.deleteTransaction(transaction?.slug);
      toast.success("Вы успешно удалили транзакцию");
      navigate("../transactions");
    } catch (error) {
      toast.error("Ошибка");
    }
  };

  return (
    <div
      className={cn(styles.modal, {
        [styles.active]: active === true,
      })}
      onClick={() => setActive(false)}
    >
      <div
        className={cn(styles.wrapper, className)}
        onClick={(e) => e.stopPropagation()}
        {...props}
      >
        <div className={styles.content}>
          <h1>Удалить транзакцию</h1>
          <p>
            Транзакция будет удалена навсегда без возможности восстановления
          </p>
          <div className={styles.btns}>
            <Button
              className={styles.btn}
              appearance="black"
              width="140px"
              height="55px"
              onClick={handleClickCancel}
            >
              Отмена
            </Button>
            <Button
              className={styles.btn}
              appearance="red"
              width="140px"
              height="55px"
              onClick={handleClickDelete}
            >
              Удалить
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
