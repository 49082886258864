// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DashboardLayout_wrapper__Eipr8 .DashboardLayout_content__rzP73 {
  display: grid;
  grid-template-columns: 220px 1fr;
  column-gap: 40px;
  margin: 50px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/layouts/DashboardLayout/DashboardLayout.module.scss"],"names":[],"mappings":"AAIE;EACE,aAAA;EACA,gCAAA;EACA,gBAAA;EACA,cAAA;AAHJ","sourcesContent":[".wrapper {\n  .screenSizePermission {\n  }\n\n  .content {\n    display: grid;\n    grid-template-columns: 220px 1fr;\n    column-gap: 40px;\n    margin: 50px 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `DashboardLayout_wrapper__Eipr8`,
	"content": `DashboardLayout_content__rzP73`
};
export default ___CSS_LOADER_EXPORT___;
