import React from "react";
import styles from "./VerifyPage.module.scss";

interface IProps {
  email: string;
}

export const VerifyPage = ({ email }: IProps): JSX.Element => {
  return (
    <div className={styles.verifyPage}>
      <h1>Подтвердите почту</h1>
      <p>
        Для окончания регистрации перейдите по ссылки из письма, которое мы вам
        отправили на <span>{email}</span>
      </p>
    </div>
  );
};
