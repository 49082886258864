import React from "react";
import styles from "./Form.module.scss";
import cn from "classnames";

interface IProps
  extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLFormElement>, HTMLFormElement> {}

export const Form = ({ children, className, ...props }: IProps): JSX.Element => {
  return (
    <form className={cn(styles.form, className)} {...props}>
      {children}
    </form>
  );
};
