import React from "react";

function ScrollToTopOnReload() {
  React.useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  return null;
}

export default ScrollToTopOnReload;
