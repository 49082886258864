// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ForgotPasswordPage_forgotPassword__nH5d7 {
  font-weight: 500;
  font-size: 40px;
  line-height: 113.7%;
  text-align: center;
}
@media (max-width: 400px) {
  .ForgotPasswordPage_forgotPassword__nH5d7 {
    font-size: 30px;
  }
}

.ForgotPasswordPage_form__2-YBg {
  margin-top: 40px;
}
.ForgotPasswordPage_form__2-YBg .ForgotPasswordPage_btn__gxYNT {
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/ForgotPasswordPage/ForgotPasswordPage.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACE;EACE;IACE,eAAA;EACJ;AACF;;AAGA;EACE,gBAAA;AAAF;AAEE;EACE,cAAA;AAAJ","sourcesContent":[".forgotPassword {\n  font-weight: 500;\n  font-size: 40px;\n  line-height: 113.7%;\n  text-align: center;\n\n  @media (max-width: 400px) {\n    & {\n      font-size: 30px;\n    }\n  }\n}\n\n.form {\n  margin-top: 40px;\n\n  .btn {\n    margin: 0 auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"forgotPassword": `ForgotPasswordPage_forgotPassword__nH5d7`,
	"form": `ForgotPasswordPage_form__2-YBg`,
	"btn": `ForgotPasswordPage_btn__gxYNT`
};
export default ___CSS_LOADER_EXPORT___;
