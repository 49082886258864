import React from "react";
import styles from "./TransactionResultPage.module.scss";
import { AlertSVG, InfoCircleSVG } from "../../../assets/icons/globals.icons";
import { Button } from "../../index.components";
import { useNavigate, useParams } from "react-router-dom";
import transactionsService from "../../../services/transactionsService";
import {
  ITransaction,
  ITransactionFile,
  ITransactionResult,
} from "../../../@types/models/transactions.types";
import { useAppSelector } from "../../../hooks/redux.hooks";
import { toast } from "react-toastify";
import { TransactionDeleteModal } from "./TransactionDeleteModal/TransactionDeleteModal";

interface Warn {
  title: string;
  desc: string;
}

export const TransactionResultPage = (): JSX.Element => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const { user } = useAppSelector((state) => state.userSlice);

  const [transaction, setTransaction] = React.useState<ITransaction | null>(
    null
  );
  const [dataResult, setDataResult] = React.useState<ITransactionResult>();

  const [isCheckedShare, setIsCheckedShare] = React.useState<boolean>(false);
  const [isCheckedContinue, setIsCheckedContinue] =
    React.useState<boolean>(false);
  const [files, setFiles] = React.useState<ITransactionFile[]>();
  const [shareAproved, setShareAproved] = React.useState<boolean>(false);
  const [warn, setWarn] = React.useState<Warn | null>(null);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [activeModal, setActiveModal] = React.useState<boolean>(false);

  const data_type = {
    inn: 'ИНН',
    email: 'Email',
    phone: 'номеров телефонов'
  }


  const ownerFile = files?.filter((file) => file.owner === user?.id)[0];

  const fetchFiles = async () => {
    const data = await transactionsService.fetchTransactionFileBySlug(slug!);
    setFiles(data);
  };
  const fetchTransaction = async () => {
    const data = await transactionsService.fetchTransactionBySlug(slug!);
    setTransaction(data);
  };

  const validateFilesForCompare = (files: ITransactionFile[] | undefined) => {
    const readyFile = files?.filter((file) => file.isShare === true);

    const partnerFile = files?.filter((file) => file.owner !== user?.id)[0];

    if (readyFile?.length === 2) {
      setWarn(null);
      setShareAproved(true);
    }

    if (partnerFile?.isShare && isCheckedContinue) {
      setWarn({
        title: "Ваш партнер хочет обменяться данными",
        desc: "Если согласны, выберите “Обменяться данными”",
      });
    }
  };

  const handleCheckboxShare = async () => {
    setIsCheckedContinue(false);
    await transactionsService.handleUpdateAgreementFile(ownerFile?.id, true);
    setIsCheckedShare(true);
    fetchFiles();
  };

  const handleCheckboxContinue = async () => {
    setIsCheckedShare(false);
    await transactionsService.handleUpdateAgreementFile(ownerFile?.id, false);
    setIsCheckedContinue(true);
    fetchFiles();
  };

  React.useEffect(() => {
    fetchTransaction();
  }, [slug]);

  React.useEffect(() => {
    if (transaction?.result) {
      const loadResultJSON = JSON.parse(transaction.result);
      setDataResult(loadResultJSON);
    }
  }, [transaction]);

  React.useEffect(() => {
    fetchFiles();
  }, []);

  React.useEffect(() => {
    if (ownerFile?.isShare) {
      setIsCheckedShare(true);
    }

    validateFilesForCompare(files);
  }, [files]);

  const handleClickDeleteTransaction = () => {
    setActiveModal(true);
  };

  const renderConfirmation = () => {
    if (transaction?.status === "COMPARE_DB") {
      return (
        <div className={styles.exchangeData}>
          <h2>
            <InfoCircleSVG />
            Вы можете обменяться данными с партнером
          </h2>
          <div className={styles.exchangeBlock}>
            <h3>Обменяться данными?</h3>
            <p className={styles.desc}>
              Обмен произойдет только при обоюдном согласии партнеров.
            </p>
            <div className={styles.boxes}>
              <div className={styles.checkbox}>
                {}
                <input
                  type="checkbox"
                  checked={isCheckedShare}
                  onChange={handleCheckboxShare}
                  value={"share"}
                />
                <p>Обменяться данными</p>
              </div>
              <div className={styles.checkbox}>
                <input
                  type="checkbox"
                  checked={isCheckedContinue}
                  onChange={handleCheckboxContinue}
                  value={"continue"}
                />
                <p>Продолжить без обмена</p>
              </div>
            </div>
          </div>
          {warn ? (
            <div className={styles.alert}>
              <AlertSVG />
              <div className={styles.error}>
                <p className={styles.title}>{warn.title}</p>
                <p className={styles.desc}>{warn.desc} </p>
              </div>
            </div>
          ) : null}
          <Button
            className={styles.btn}
            appearance="black"
            width="330px"
            height="55px"
            isSubmitting={isSubmitting}
            disabled={isSubmitting}
            onClick={handleClickButton}
          >
            Отправить результат на почту
          </Button>
        </div>
      );
    } else if (transaction?.status === "SHARE_DB") {
      return (
        <div className={styles.exchangeData}>
          <h2>
            <InfoCircleSVG />
            Вы можете обменяться данными с партнером
          </h2>
          <div className={styles.exchangeBlock}>
            <h3>Обменяться данными?</h3>
            <p className={styles.desc}>
              Обмен произойдет только при обоюдном согласии партнеров.
            </p>
            <div className={styles.boxes}>
              <div className={styles.checkbox}>
                {}
                <input type="checkbox" checked={true} value={"share"} />
                <p>Обменяться данными</p>
              </div>
              <div className={styles.checkbox}>
                <input type="checkbox" checked={false} value={"continue"} />
                <p>Продолжить без обмена</p>
              </div>
            </div>
          </div>
          {warn ? (
            <div className={styles.alert}>
              <AlertSVG />
              <div className={styles.error}>
                <p className={styles.title}>{warn.title}</p>
                <p className={styles.desc}>{warn.desc} </p>
              </div>
            </div>
          ) : null}
          <div className={styles.btns}>
            <Button
              className={styles.btn}
              appearance="black"
              width="330px"
              height="55px"
              disabled={true}
            >
              Результат отправлен на почту
            </Button>
            <Button
              className={styles.btn}
              appearance="red"
              width="240px"
              height="55px"
              onClick={handleClickDeleteTransaction}
            >
              Удалить транзацию
            </Button>
            <TransactionDeleteModal
              active={activeModal}
              setActive={setActiveModal}
              transaction={transaction}
            />
          </div>
        </div>
      );
    } else if (transaction?.status === "CONTINUE") {
      return (
        <div className={styles.exchangeData}>
          <h2>
            <InfoCircleSVG />
            Вы можете обменяться данными с партнером
          </h2>
          <div className={styles.exchangeBlock}>
            <h3>Обменяться данными?</h3>
            <p className={styles.desc}>
              Обмен произойдет только при обоюдном согласии партнеров.
            </p>
            <div className={styles.boxes}>
              <div className={styles.checkbox}>
                {}
                <input type="checkbox" checked={false} value={"share"} />
                <p>Обменяться данными</p>
              </div>
              <div className={styles.checkbox}>
                <input type="checkbox" checked={true} value={"continue"} />
                <p>Продолжить без обмена</p>
              </div>
            </div>
          </div>
          {warn ? (
            <div className={styles.alert}>
              <AlertSVG />
              <div className={styles.error}>
                <p className={styles.title}>{warn.title}</p>
                <p className={styles.desc}>{warn.desc} </p>
              </div>
            </div>
          ) : null}
          <div className={styles.btns}>
            <Button
              className={styles.btn}
              appearance="black"
              width="330px"
              height="55px"
              disabled={true}
            >
              Результат отправлен на почту
            </Button>
            <Button
              className={styles.btn}
              appearance="red"
              width="240px"
              height="55px"
              onClick={handleClickDeleteTransaction}
            >
              Удалить транзакцию
            </Button>
            <TransactionDeleteModal
              active={activeModal}
              setActive={setActiveModal}
              transaction={transaction}
            />
          </div>
        </div>
      );
    }
  };
  console.log(dataResult)
  const handleClickButton = async () => {
    setIsSubmitting(true);

    fetchTransaction();

    if (transaction?.status === "SHARE_DB") {
      toast.success("Вы успешно обменялись данными");
      navigate(`../transactions/`);
    }

    if (transaction?.status === "CONTINUE") {
      toast.success(`На почту отправлен результат совпавшихся ${dataResult?.data_type}`);
      navigate("../transactions/");
    }

    if (transaction?.status === "COMPARE_DB") {
      if (isCheckedShare && shareAproved) {
        await transactionsService.handleUpdateStatusTransaction(
          slug!,
          "SHARE_DB"
        );
        toast.success("Вы успешно обменялись данными");
        navigate(`../transactions/`);
      }

      if (isCheckedShare && !shareAproved) {
        setWarn({
          title: "Дождитесь партнера",
          desc: "",
        });
      }

      if (isCheckedContinue) {
        await transactionsService.handleUpdateStatusTransaction(
          slug!,
          "CONTINUE"
        );
        toast.success(`На почту отправлен результат совпавшихся ${dataResult?.data_type}`);
        navigate("../transactions/");
      }

      if (!isCheckedContinue && !isCheckedShare) {
        setWarn({
          title: "Выберите один из вариантов",
          desc: "",
        });
      }
    }

    setIsSubmitting(false);
  };

  return (
    <div className={styles.transactionResultPage}>
      <h1>Транзакция #{transaction?.slug}</h1>
      <div className={styles.info}>
        {transaction?.sender.email === user?.email ? (
          <>
            <p className={styles.desc}>
              Результаты сравнения баз данных с партнером{" "}
              {dataResult?.sender.partner_email}
            </p>
            <p className={styles.all}>
              Всего сверено {dataResult?.data_type} из 2х списков{" "}
              {parseInt(dataResult?.sender.length_of_db_partner!) +
                parseInt(dataResult?.recipient.length_of_db_partner!)}
            </p>
            <h2>Список партнера ({dataResult?.sender.partner_email})</h2>
            <div className={styles.partner}>
              <div className={styles.item}>
                <p>Всего {dataResult?.data_type} в списке партнера</p>
                <p>{dataResult?.sender.length_of_db_partner}</p>
              </div>
              <div className={styles.item}>
                <p>Общих {dataResult?.data_type} с партнером1</p>
                <p>{dataResult?.length_overlaping_data}</p>
              </div>
              <div className={styles.item}>
                <p>{dataResult?.data_type}, которых нет в вашем списке</p>
                <p>{dataResult?.sender.unique_data_of_db_partner}</p>
              </div>
              {/* <div className={styles.item}>
                <p>Новая доп информация по пересёкшимся {dataResult?.data_type}</p>
                <p>{dataResult?.sender.length_of_info_recipient}</p>
              </div> */}
            </div>

            <h2>Ваш список</h2>

            <div className={styles.partner}>
              <div className={styles.item}>
                <p>Новая доп информация по пересёкшимся {dataResult?.data_type} для партнера</p>
                <p>{dataResult?.sender.length_of_info_sender}</p>
              </div>
            </div>

            <div style={{ marginTop: "40px" }}>
              По {dataResult?.length_overlaping_data} общим компаниям у партнера
              есть дополнительная информация по {" "}
              {dataResult?.sender.length_of_info_recipient} клиентам, а у вас по{" "}
              {dataResult?.sender.length_of_info_sender}. Вы можете обогатить
              свою базу и базу партнера новой информацией по клиентам.
            </div>
          </>
        ) : (
          <>
            <p className={styles.desc}>
              Результаты сравнения баз данных с партнером{" "}
              {dataResult?.recipient.partner_email}
            </p>
            <p className={styles.all}>
              Всего сверено {dataResult?.data_type} из 2х списков{" "}
              {parseInt(dataResult?.sender.length_of_db_partner!) +
                parseInt(dataResult?.recipient.length_of_db_partner!)}
            </p>
            <h2>Список партнера ({dataResult?.recipient.partner_email})</h2>
            <div className={styles.partner}>
              <div className={styles.item}>
                <p>Всего {dataResult?.data_type} в списке партнера</p>
                <p>{dataResult?.recipient.length_of_db_partner}</p>
              </div>
              <div className={styles.item}>
                <p>Общих {dataResult?.data_type} с партнером</p>
                <p>{dataResult?.length_overlaping_data}</p>
              </div>
              <div className={styles.item}>
                <p>{dataResult?.data_type}, которых нет в вашем списке</p>
                <p>{dataResult?.recipient.unique_data_of_db_partner}</p>
              </div>
              {/* <div className={styles.item}>
                <p>Новая доп информация по пересёкшимся {dataResult?.data_type}</p>
                <p>{dataResult?.recipient.length_of_info_recipient}</p>
              </div> */}
            </div>

            <h2>Ваш список</h2>

            <div className={styles.partner}>
              <div className={styles.item}>
                <p>Новая доп информация по пересёкшимся {dataResult?.data_type} для партнера</p>
                <p>{dataResult?.recipient.length_of_info_sender}</p>
              </div>
            </div>

            <div style={{ marginTop: "40px" }}>
              По {dataResult?.length_overlaping_data} общим компаниям у партнера
              есть дополнительная информация по {" "}
              {dataResult?.recipient.length_of_info_recipient} клиентам, а у вас
              по {dataResult?.recipient.length_of_info_sender}. Вы можете
              обогатить свою базу и базу партнера новой информацией по клиентам.
            </div>
          </>
        )}
      </div>
      {renderConfirmation()}
    </div>
  );
};
