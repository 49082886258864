const removeDecimal = (value: number | undefined): string | undefined => {
  if (value === undefined) {
    return undefined;
  }

  const splitValue = value.toString().split(".");

  return splitValue[0];
};

export default removeDecimal;
