// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SignUpPage_signUp__iAM2H {
  font-weight: 500;
  font-size: 40px;
  line-height: 113.7%;
  text-align: center;
}
@media (max-width: 400px) {
  .SignUpPage_signUp__iAM2H {
    font-size: 30px;
  }
}

.SignUpPage_desc__lbu\\+9 {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: rgb(67, 69, 79);
  text-align: center;
  margin-top: 10px;
}

.SignUpPage_form__u\\+wEj {
  margin-top: 40px;
}
.SignUpPage_form__u\\+wEj .SignUpPage_rules__k5xDk {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 15px;
  margin-bottom: 20px;
}
.SignUpPage_form__u\\+wEj .SignUpPage_rules__k5xDk .SignUpPage_item__TGZFj {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.SignUpPage_form__u\\+wEj .SignUpPage_rules__k5xDk .SignUpPage_item__TGZFj .SignUpPage_circle__i6VE9 {
  width: 8px;
  height: 8px;
  background: linear-gradient(0deg, #54e9b8, #54e9b8), #ffffff;
  border-radius: 50%;
}
.SignUpPage_form__u\\+wEj .SignUpPage_rules__k5xDk .SignUpPage_item__TGZFj p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: rgb(67, 69, 79);
}
@media (max-width: 1040px) {
  .SignUpPage_form__u\\+wEj .SignUpPage_rules__k5xDk {
    grid-template-columns: 1fr;
    row-gap: 5px;
  }
}
.SignUpPage_form__u\\+wEj .SignUpPage_btn__WrBap {
  margin: 0 auto;
}

.SignUpPage_confirmation__B6VpC {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: rgb(67, 69, 79);
  margin-top: 15px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/SignUpPage/SignUpPage.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,kBAAA;AACF;AACE;EACE;IACE,eAAA;EACJ;AACF;;AAGA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,kBAAA;EACA,gBAAA;AAAF;;AAGA;EACE,gBAAA;AAAF;AAEE;EACE,aAAA;EACA,qCAAA;EACA,aAAA;EACA,mBAAA;AAAJ;AAEI;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AAAN;AAEM;EACE,UAAA;EACA,WAAA;EACA,4DAAA;EACA,kBAAA;AAAR;AAGM;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;AADR;AAKI;EACE;IACE,0BAAA;IACA,YAAA;EAHN;AACF;AAOE;EACE,cAAA;AALJ;;AASA;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,sBAAA;EACA,gBAAA;EACA,kBAAA;AANF","sourcesContent":[".signUp {\n  font-weight: 500;\n  font-size: 40px;\n  line-height: 113.7%;\n  text-align: center;\n\n  @media (max-width: 400px) {\n    & {\n      font-size: 30px;\n    }\n  }\n}\n\n.desc {\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 26px;\n  color: rgba(67, 69, 79, 1);\n  text-align: center;\n  margin-top: 10px;\n}\n\n.form {\n  margin-top: 40px;\n\n  .rules {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    row-gap: 15px;\n    margin-bottom: 20px;\n\n    .item {\n      display: flex;\n      align-items: center;\n      column-gap: 10px;\n\n      .circle {\n        width: 8px;\n        height: 8px;\n        background: linear-gradient(0deg, #54e9b8, #54e9b8), #ffffff;\n        border-radius: 50%;\n      }\n\n      p {\n        font-weight: 400;\n        font-size: 12px;\n        line-height: 16px;\n        color: rgba(67, 69, 79, 1);\n      }\n    }\n\n    @media (max-width: 1040px) {\n      & {\n        grid-template-columns: 1fr;\n        row-gap: 5px;\n      }\n    }\n  }\n\n  .btn {\n    margin: 0 auto;\n  }\n}\n\n.confirmation {\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 22px;\n  color: rgba(67, 69, 79, 1);\n  margin-top: 15px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signUp": `SignUpPage_signUp__iAM2H`,
	"desc": `SignUpPage_desc__lbu+9`,
	"form": `SignUpPage_form__u+wEj`,
	"rules": `SignUpPage_rules__k5xDk`,
	"item": `SignUpPage_item__TGZFj`,
	"circle": `SignUpPage_circle__i6VE9`,
	"btn": `SignUpPage_btn__WrBap`,
	"confirmation": `SignUpPage_confirmation__B6VpC`
};
export default ___CSS_LOADER_EXPORT___;
