import React from "react";
import styles from "./LoadingPopup.module.scss";
import {LogoSVG} from "../../../assets/icons/globals.icons";
import {LoadingAnimationWave} from "./LoadingAnimationWave";

export const LoadingPopup = (): JSX.Element => {
  return (
    <div className={styles.loading}>
      <LogoSVG className={styles.logo}/>
      <LoadingAnimationWave/>
    </div>
  );
};